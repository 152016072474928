
@import "../scss/variables";


body.dark-theme {
  background: #101329;
  color: #fdfeff;
}

.dark-theme {
  .box-shadow, .custom-card {
    box-shadow: 0 0 0 1px rgba(30, 32, 47, 0.12), 0 8px 16px 0 rgba(30, 32, 47, 0.24);
  }
}

::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.2);
}

*:hover::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.1);
}

.dark-theme {
  .card-options {
    color: #6b6b84;

    a:not(.btn) {
      color: #6b6b84;

      &:hover {
        color: #334151;
      }
    }
  }

  .custom-card-header {
    background: #1c1c38;
  }

  .card-body + .card-body {
    border-top: 1px solid rgba(255, 255, 255, 0.05);
  }

  .main-navbar .nav-sub-link:before {
    color: #6b6b84;
  }

  .page-header .breadcrumb-item {
    a {
      color: #fdfeff;
    }

    &.active {
      color: #33689C;
    }
  }

  #back-to-top {
    color: #fff;
    background: #33689C;

    &:hover {
      color: #33689C !important;
      border: 2px solid #33689C;
      background: #1c1c38 !important;
    }
  }

  .contact-icon {
    color: #fff;

    &:hover {
      background: rgba(255, 255, 255, 0.05);
    }
  }

  .accordion-color .card-header a {
    color: #33689C;

    &:hover, &:focus {
      color: #33689C;
    }

    &.collapsed {
      background-color: #33689C;
      color: rgba(255, 255, 255, 0.8);

      &:hover, &:focus {
        background-color: #33689C;
        color: #fff;
      }
    }
  }

  .component-item a:before {
    color: #6b6b84;
  }

  .main-icon-list {
    border: 1px solid rgba(255, 255, 255, 0.05);
  }

  .skill-tags a {
    color: #6b6b84;
    border: 1px solid rgba(255, 255, 255, 0.05);
  }

  .reviewnavs li a {
    color: #6b6b84;
  }

  .main-header-right .nav-link.icon {
    color: #fdfeff;
  }

  .dash-icon {
    color: #6b6b84;
  }

  .sidebar-body h5 {
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  }

  .pricing-tabs .nav-price li {
    a {
      color: #fdfeff;
      background: #1c1c38;
      border: 1px solid rgba(255, 255, 255, 0.05);
    }

    .active {
      background: rgba(255, 255, 255, 0.05);
      color: #fdfeff;
    }
  }

  .vtimeline {
    &:before {
      background-color: rgba(255, 255, 255, 0.1);
    }

    .timeline-wrapper {
      .timeline-panel {
        background: #252542;
        box-shadow: 0 5px 12px 0 rgb(16, 19, 41);

        &:after {
          border-top: 14px solid transparent;
          border-left: 14px solid #252542;
          border-right: 0 solid #252542;
          border-bottom: 14px solid transparent;
        }
      }

      &.timeline-inverted .timeline-panel:after {
        border-left-width: 0;
        border-right-width: 14px;
        left: -14px;
        right: auto;
      }

      .timeline-panel .timeline-title {
        color: #fff;
      }

      .timeline-badge {
        border: 2px solid rgba(16, 19, 41, 0.95);
      }
    }
  }

  .timeline-body p {
    color: #6b6b84;
  }

  .vtimeline .timeline-wrapper .timeline-badge i {
    color: #ffffff;
  }

  .timeline-wrapper-light {
    .timeline-panel:before, .timeline-badge {
      background: #24243e;
    }
  }

  .timeline-wrapper-dark {
    .timeline-panel:before, .timeline-badge {
      background: #000;
    }
  }

  .example {
    border: 1px solid rgba(255, 255, 255, 0.05);
  }

  .highlight {
    border: 1px solid rgba(255, 255, 255, 0.05);
    background: #252542;
  }

  .clipboard-icon {
    background: #1c1c38;
    border: 1px solid rgba(255, 255, 255, 0.05);
    box-shadow: 0 5px 12px 0 rgb(16, 19, 41);
  }

  .our-team {
    .picture::after {
      background-color: #33689C;
    }

    &:hover .picture img {
      box-shadow: 0 0 0 14px rgba(255, 255, 255, 0.05);
    }
  }

  .rating-stars .rating-stars-container .rating-star {
    color: rgba(255, 255, 255, 0.1);

    &.is--active .fa-heart, &.is--hover .fa-heart {
      color: #fb0d00;
    }

    &.sm {
      color: rgba(255, 255, 255, 0.1);
    }

    &.is--active, &.is--hover {
      color: #f1c40f;
    }

    &.is--no-hover, .fa-heart .is--no-hover {
      color: #252542;
    }
  }

  .br-theme-bars-horizontal .br-widget {
    a {
      background-color: rgba(255, 255, 255, 0.05);

      &.br-active, &.br-selected {
        background-color: #33689C;
      }
    }

    .br-current-rating {
      color: #33689C;
    }
  }

  .br-theme-bars-pill .br-widget a {
    background-color: rgba(255, 255, 255, 0.08);

    &.br-active, &.br-selected {
      background-color: #33689C;
      color: white;
    }

    color: #33689C;
  }

  .br-theme-bars-square .br-widget a {
    &.br-active, &.br-selected {
      border: 2px solid rgba(255, 255, 255, 0.05);
      color: #33689C;
    }

    border: 2px solid rgba(255, 255, 255, 0.05);
    background-color: rgba(255, 255, 255, 0.08);
    color: #6b6b84;
  }

  .br-theme-bars-movie .br-widget {
    a {
      &.br-active, &.br-selected {
        background-color: #33689C;
      }

      background-color: rgba(255, 255, 255, 0.1);
    }

    .br-current-rating {
      color: #33689C;
    }
  }

  .br-theme-bars-1to10 .br-widget {
    a {
      background-color: rgba(255, 255, 255, 0.1);

      &.br-active, &.br-selected {
        background-color: #33689C;
      }
    }

    .br-current-rating {
      color: #33689C;
    }
  }

  .br-theme-fontawesome-stars .br-widget a {
    &.br-active:after, &.br-selected:after {
      color: #33689C;
    }
  }

  .rating-stars input {
    color: #6b6b84;
    background-color: #252542;
    border: 1px solid rgba(255, 255, 255, 0.05);
  }

  .task-list:after {
    background: rgba(255, 255, 255, 0.1);
  }

  .product_price .old_price {
    color: #6b6b84;
  }

  .carousel-slider #thumbcarousel .carousel-item .thumb:hover {
    border: 1px solid rgba(255, 255, 255, 0.08);
  }

  .carousel-control {
    &:active, &:focus, &:hover {
      color: #333;
    }
  }

  #thumbcarousel {
    .carousel-control-prev, .carousel-control-next {
      background: rgba(0, 0, 0, 0.8);
    }
  }

  .owl-nav button {
    background: rgba(255, 255, 255, 0.05) !important;
    border: 1px solid rgba(255, 255, 255, 0.05) !important;
    box-shadow: 0 4px 15px rgba(67, 67, 67, 0.15);

    &:before {
      background: #2098d1;
    }
  }

  .owl-carousel:hover .owl-nav button {
    background: rgba(255, 255, 255, 0.1) !important;
  }

  .owl-theme .owl-dots .owl-dot span {
    background: rgba(0, 0, 0, 0.3);
  }

  .product-carousel .thumb {
    border: 1px solid rgba(255, 255, 255, 0.05);
  }

  .main-profile-menu .border-top {
    border-top: 1px solid rgba(255, 255, 255, 0.05) !important;
  }

  .custom-switch-indicator {
    background: #252542;
    border: 1px solid rgba(255, 255, 255, 0.05);

    &:before {
      background: #fff;
    }
  }

  .custom-switch-input {
    &:checked ~ .custom-switch-indicator {
      background: #33689C;
    }

    &:focus ~ .custom-switch-indicator {
      border-color: #33689C;
    }
  }

  .custom-switch-description, .custom-switch-input:checked ~ .custom-switch-description {
    color: #6b6b84;
  }

  .selectgroup-button {
    border: 1px solid rgba(255, 255, 255, 0.05);
    color: #6b6b84;
  }

  .selectgroup-input {
    &:checked + .selectgroup-button {
      border-color: #33689C;
      color: #33689C;
      background: #252542;
    }

    &:focus + .selectgroup-button {
      border-color: #33689C;
      color: #33689C;
      box-shadow: none;
    }
  }

  .colorinput-color {
    color: #fff;
  }

  .ql-toolbar.ql-snow .ql-picker-options {
    border: 1px solid transparent;
    box-shadow: #242433 0 2px 8px;
  }

  .ql-snow .ql-picker-options {
    background-color: #1c1c38;
  }

  .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
    border-color: rgba(255, 255, 255, 0.05);
  }
}


@media (max-width: 992px) {
  .dark-theme {
    .main-navbar .nav-item {
      border-bottom: 1px solid rgba(255, 255, 255, 0.05);
    }

    .main-header {
      box-shadow: 0 5px 12px 0 rgb(16, 19, 41);
      border-bottom: 1px solid rgba(255, 255, 255, 0.05);
    }

    .main-navbar .nav-item.show > .nav-sub {
      border: 0;
      box-shadow: none;
    }
  }
}

@media (max-width: 767px) {
  .dark-theme {
    .main-content-body-contacts {
      border-top: 1px solid rgba(255, 255, 255, 0.05);
    }

    .vtimeline .timeline-wrapper .timeline-panel:after {
      border-top: 14px solid transparent !important;
      border-right: 14px solid #252542 !important;
      border-left: 0 solid #252542 !important;
      border-bottom: 14px solid transparent !important;
    }
  }
}

/* Back-top-button*/

/* Timeline */
/*--- Vertical --*/

/*-- rating--*/

/*-----Br Themes-----*/

/*-----Br Themes-----*/

/* Thumb Carousel */

/*----Custom Switch-----*/

/*----Select Group-----*/

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  border-color: rgba(255, 255, 255, 0.05);
}

/* Tags */

.dark-theme {
  .tag-addon {
    background: rgba(255, 255, 255, 0.04);
  }

  .tag {
    color: #fff;
  }

  .tag-default {
    background-color: rgba(255, 255, 255, 0.05);
    color: #fdfeff;
  }

  .tag-dark {
    background-color: #000;
  }

  .mapael {
    .zoomButton {
      background-color: #292929;
      border: 1px solid #292929;
      color: #fff;
    }

    .mapTooltip {
      background-color: #474c4b;
      color: #fff;
    }
  }

  .dt-button-collection .dropdown-item {
    &.active, &:active {
      background-color: #1c1c38;
    }
  }

  .pulse {
    background: #ff473d;
    box-shadow: 0 0 0 rgba(255, 71, 61, 0.9);
  }

  .main-header-notification.show .dropdown-menu::before, .main-profile-menu.show .dropdown-menu::before {
    background: #242444;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    border-left: 1px solid rgba(255, 255, 255, 0.1);
  }

  .page-header .breadcrumb {
    background: none;
  }

  .advanced-search {
    background: #1c1c38;
    box-shadow: 0 0 0 1px rgba(30, 32, 47, 0.12), 0 8px 16px 0 rgba(30, 32, 47, 0.24);
    border: 1px solid rgba(255, 255, 255, 0.05);
  }

  .demo_changer .form_holder {
    box-shadow: 0 5px 12px 0 rgb(43, 43, 70);
  }

  .demo-icon.bg_dark {
    background: #fff;
  }

  .demo_changer i {
    color: #000;
  }

  .bg-success-transparent {
    background-color: rgba(56, 210, 80, 0.1);
  }

  .bg-secondary-transparent {
    background-color: rgba(236, 131, 27, 0.1);
  }

  .bg-primary-transparent {
    background-color: rgba(135, 96, 251, 0.1);
  }

  .bg-info-transparent {
    background-color: rgba(1, 184, 255, 0.1);
  }

  .bg-warning-transparent {
    background-color: rgba(253, 160, 8, 0.1);
  }

  .bg-danger-transparent {
    background-color: rgba(255, 71, 61, 0.1);
  }

  .bg-pink-transparent {
    background-color: rgba(241, 0, 117, 0.1);
  }

  .bg-purple-transparent {
    background-color: rgba(111, 66, 193, 0.1);
  }

  .bg-dark-transparent {
    background-color: rgba(28, 39, 60, 0.1);
  }

  .bg-white-transparent {
    background-color: rgba(255, 255, 255, 0.1);
  }

  .error-bg {
    background: url(../img/pngs/bg1.png);
    background-position: center;
    background-size: cover;
  }

  .header-brand-img {
    display: none;

    &.theme-logos {
      display: block;
      margin: 0 auto;
    }
  }

  .header-search.show .dropdown-menu {
    background-color: #242444;
    box-shadow: 0 5px 12px 0 rgb(31, 33, 51);

    &::before {
      background: #242444;
      border-top: 1px solid rgba(255, 255, 255, 0.1);
      border-left: 1px solid rgba(255, 255, 255, 0.1);
    }
  }

  .main-form-search {
    .btn:hover, .sp-container button:hover {
      color: #fff;
    }
  }

  .sp-container .main-form-search button:hover {
    color: #fff;
  }

  .main-form-search {
    .btn:focus, .sp-container button:focus {
      color: #fff;
    }
  }

  .sp-container .main-form-search button:focus {
    color: #fff;
  }

  .main-form-search .form-control {
    background-color: #151529;
  }

  .card-blog-overlay .custom-card-header, .card-blog-overlay1 .custom-card-header, .card-blog-overlay2 .custom-card-header, .card-blog-overlay .card-footer, .card-blog-overlay1 .card-footer, .card-blog-overlay2 .card-footer {
    background: none;
  }

  .card {
    &.card-blog-overlay2.custom-card, &.card-blog-overlay1.custom-card, &.card-blog-overlay.custom-card {
      border: 0;
    }
  }

  .contact-info .contact-icon:hover {
    color: #fff;
  }

  #global-loader {
    background: #1c1c38;
  }

  #example-input .form-control {
    &::placeholder {
      color: #fdfeff !important;
    }

    color: #fdfeff !important;
  }

  .main-header-message.show .dropdown-menu::before, .main-header-flags.show .dropdown-menu::before {
    background: #242444;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    border-left: 1px solid rgba(255, 255, 255, 0.1);
  }
}


/*-- Bg-transparent---*/

/* Leftmenu Toggle*/

.app.dark-theme {
  .second-sidemenu {
    background: #1e1e3e;
    box-shadow: 6px 16px 31px -18px #323246;
  }

    .resp-vtabs{
		.first-sidemenu li.active {
      background: #27234c !important;
    }

    ul.resp-tabs-list {
      background: #1c1c38;
    }

    .resp-tabs-list li {
      border-right: 1px solid rgba(255, 255, 255, 0.05) !important;
    }

    .first-sidemenu li {
      &.resp-tab-active {
        background-color: #1e1e3e !important;
        border-right: 0px !important;
      }

      &:hover {
        background: #1e1e3e !important;
      }
    }
  }

  &.sidebar-mini .slide-item {
    color: #bfbfe0;
  }

  &.sidenav-toggled .first-sidemenu {
    box-shadow: 6px 16px 31px -18px #272035;
  }

  .first-sidemenu {
    background: #1c1c38;
  }

  .nav-link.toggle span {
    background-color: #fff;

    &::before, &::after {
      background-color: #fff;
    }
  }

  .slide-item {
    &.active, &:hover, &:focus {
      color: #8645ff;
    }
  }

  .side-header {
    box-shadow: none;
  }
}


.dark-theme {
  .tx-inverse, .main-notification-text {
    color: #8283a0;
  }

  .tx-gray-500 {
    color: #797b94;
  }

  caption {
    color: #6b6b84;
  }

  hr {
    border-top: 1px solid rgba(255, 255, 255, 0.05);
  }

  mark, .mark {
    background-color: #fcf8e3;
  }

  .blockquote-footer {
    color: #6b6b84;
  }

  .img-thumbnail {
    background-color: #1c1c38;
    border: 1px solid rgba(255, 255, 255, 0.05);
  }

  .figure-caption {
    color: #6b6b84;
  }

  code {
    color: #f10075;
  }

  kbd {
    color: $white;
    background-color: #fdfeff;
  }

  pre {
    background-color: #252542;
    text-shadow: 0 1px #1c1c38;
  }

  .table {
    color: #334151;

    th, td {
      border-top: 1px solid rgba(255, 255, 255, 0.05);
    }

    &.dataTable {
      th, td {
        border-left: 1px solid rgba(255, 255, 255, 0.05);
      }
    }

    thead th {
      border-bottom: 1px solid rgba(255, 255, 255, 0.05);
      border-top: 1px solid transparent;
    }

    tbody + tbody {
      border-top: 2px solid rgba(255, 255, 255, 0.05);
    }
  }

  .table-bordered {
    border: 1px solid rgba(255, 255, 255, 0.05);

    th, td {
      border: 1px solid rgba(255, 255, 255, 0.05);
    }
  }

  .table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }

  .table-hover tbody tr:hover {
    color: #fdfeff;
    background-color: #24243e;
  }

  .table-primary, table-primary > th {
    background-color: #c6d4ff;
  }

  .table-primary {
    > td {
      background-color: #c6d4ff;
    }

    th, td, thead th, tbody + tbody {
      border-color: #95afff;
    }
  }

  .table-hover .table-primary:hover {
    background-color: #adc1ff;

    > {
      td, th {
        background-color: #adc1ff;
      }
    }
  }

  .table-secondary {
    background-color: #d9dde5;

    > {
      th, td {
        background-color: #d9dde5;
      }
    }

    th, td, thead th, tbody + tbody {
      border-color: #b9c1ce;
    }
  }

  .table-hover .table-secondary:hover {
    background-color: #cacfdb;

    > {
      td, th {
        background-color: #cacfdb;
      }
    }
  }

  .table-success {
    background-color: #c8e9b8;

    > {
      th, td {
        background-color: #c8e9b8;
      }
    }

    th, td, thead th, tbody + tbody {
      border-color: #99d67b;
    }
  }

  .table-hover .table-success:hover {
    background-color: #b9e3a5;

    > {
      td, th {
        background-color: #b9e3a5;
      }
    }
  }

  .table-info {
    background-color: #bee5eb;

    > {
      th, td {
        background-color: #bee5eb;
      }
    }

    th, td, thead th, tbody + tbody {
      border-color: #86cfda;
    }
  }

  .table-hover .table-info:hover {
    background-color: #abdde5;

    > {
      td, th {
        background-color: #abdde5;
      }
    }
  }

  .table-warning {
    background-color: #ffeeba;

    > {
      th, td {
        background-color: #ffeeba;
      }
    }

    th, td, thead th, tbody + tbody {
      border-color: #ffdf7e;
    }
  }

  .table-hover .table-warning:hover {
    background-color: #ffe8a1;

    > {
      td, th {
        background-color: #ffe8a1;
      }
    }
  }

  .table-danger {
    background-color: #f5c6cb;

    > {
      th, td {
        background-color: #f5c6cb;
      }
    }

    th, td, thead th, tbody + tbody {
      border-color: #ed969e;
    }
  }

  .table-hover .table-danger:hover {
    background-color: #f1b0b7;

    > {
      td, th {
        background-color: #f1b0b7;
      }
    }
  }

  .table-light {
    background-color: #fcfcfd;

    > {
      th, td {
        background-color: #fcfcfd;
      }
    }

    th, td, thead th, tbody + tbody {
      border-color: #f9fafb;
    }
  }

  .table-hover .table-light:hover {
    background-color: #ededf3;

    > {
      td, th {
        background-color: #ededf3;
      }
    }
  }

  .table-dark {
    background-color: #c8ccd3;

    > {
      th, td {
        background-color: #c8ccd3;
      }
    }

    th, td, thead th, tbody + tbody {
      border-color: #99a0ae;
    }
  }

  .table-hover .table-dark:hover {
    background-color: #babfc8;

    > {
      td, th {
        background-color: #babfc8;
      }
    }
  }

  .table-active {
    background-color: rgba(0, 0, 0, 0.075);

    > {
      th, td {
        background-color: rgba(0, 0, 0, 0.075);
      }
    }
  }

  .table-hover .table-active:hover {
    background-color: rgba(0, 0, 0, 0.075);

    > {
      td, th {
        background-color: rgba(0, 0, 0, 0.075);
      }
    }
  }

  .table {
    .thead-dark th {
      color: $white;
      background-color: #fdfeff;
      border-color: #49597b;
    }

    .thead-light th {
      color: #fdfeff;
      background-color: rgba(255, 255, 255, 0.05);
      border-color: rgba(255, 255, 255, 0.05);
    }
  }

  .table-dark {
    color: $white;
    background-color: #fdfeff;

    th, td, thead th {
      border-color: #49597b;
    }

    &.table-striped tbody tr:nth-of-type(odd) {
      background-color: rgba(255, 255, 255, 0.05);
    }

    &.table-hover tbody tr:hover {
      color: $white;
      background-color: rgba(255, 255, 255, 0.075);
    }
  }

  .form-control {
    color: #6b6b84;
    background-color: #252542;
    border: 1px solid rgba(255, 255, 255, 0.05);

    &:focus {
      color: $white;
      background-color: #252542;
      border: 1px solid rgba(255, 255, 255, 0.08);
	  outline: 0;
    }

    &::placeholder {
      color: #6b6b84;
      opacity: 1;
    }

    &:disabled, &[readonly] {
      background-color: #27273e;
      opacity: 1;
    }
  }

  select.form-control:focus::-ms-value {
    color: #334151;
    background-color: $white;
  }

  .form-control-plaintext {
    color: #334151;
  }

  .form-check-input:disabled ~ .form-check-label {
    color: #6b6b84;
  }

  .valid-feedback {
    color: #03c895;
  }

  .valid-tooltip {
    color: $white;
    background-color: rgba(59, 176, 1, 0.9);
  }

  .was-validated .form-control:valid, .form-control.is-valid, input.form-control.parsley-success, textarea.form-control.parsley-success, .was-validated .form-control:valid:focus, .form-control.is-valid:focus, input.form-control.parsley-success:focus, textarea.form-control.parsley-success:focus, .was-validated .custom-select:valid, .custom-select.is-valid, input.custom-select.parsley-success, textarea.custom-select.parsley-success, .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus, input.custom-select.parsley-success:focus, textarea.custom-select.parsley-success:focus {
    border-color: #03c895;
  }

  .was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label, input.form-check-input.parsley-success ~ .form-check-label, textarea.form-check-input.parsley-success ~ .form-check-label, .was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label, input.custom-control-input.parsley-success ~ .custom-control-label, textarea.custom-control-input.parsley-success ~ .custom-control-label {
    color: #03c895;
  }

  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before, input.custom-control-input.parsley-success ~ .custom-control-label::before, textarea.custom-control-input.parsley-success ~ .custom-control-label::before {
    border-color: #03c895;
  }

  .was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before, input.custom-control-input.parsley-success:checked ~ .custom-control-label::before, textarea.custom-control-input.parsley-success:checked ~ .custom-control-label::before {
    border-color: #4ce301;
    background-color: #4ce301;
  }

  .was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before, input.custom-control-input.parsley-success:focus ~ .custom-control-label::before, textarea.custom-control-input.parsley-success:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(59, 176, 1, 0.25);
  }

  .was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before, input.custom-control-input.parsley-success:focus:not(:checked) ~ .custom-control-label::before, textarea.custom-control-input.parsley-success:focus:not(:checked) ~ .custom-control-label::before, .was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label, input.custom-file-input.parsley-success ~ .custom-file-label, textarea.custom-file-input.parsley-success ~ .custom-file-label {
    border-color: #03c895;
  }

  .was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label, input.custom-file-input.parsley-success:focus ~ .custom-file-label, textarea.custom-file-input.parsley-success:focus ~ .custom-file-label {
    border-color: #03c895;
    box-shadow: 0 0 0 0.2rem rgba(59, 176, 1, 0.25);
  }

  .invalid-feedback {
    color: #ff473d;
  }

  .invalid-tooltip {
    color: $white;
    background-color: rgba(220, 53, 69, 0.9);
  }

  .was-validated .form-control:invalid, .form-control.is-invalid, input.form-control.parsley-error, textarea.form-control.parsley-error, .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, input.form-control.parsley-error:focus, textarea.form-control.parsley-error:focus, .was-validated .custom-select:invalid, .custom-select.is-invalid, input.custom-select.parsley-error, textarea.custom-select.parsley-error, .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus, input.custom-select.parsley-error:focus, textarea.custom-select.parsley-error:focus {
    border-color: #ff473d;
  }

  .was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label, input.form-check-input.parsley-error ~ .form-check-label, textarea.form-check-input.parsley-error ~ .form-check-label, .was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label, input.custom-control-input.parsley-error ~ .custom-control-label, textarea.custom-control-input.parsley-error ~ .custom-control-label {
    color: #ff473d;
  }

  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before, input.custom-control-input.parsley-error ~ .custom-control-label::before, textarea.custom-control-input.parsley-error ~ .custom-control-label::before {
    border-color: #ff473d;
  }

  .was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before, input.custom-control-input.parsley-error:checked ~ .custom-control-label::before, textarea.custom-control-input.parsley-error:checked ~ .custom-control-label::before {
    border-color: #e4606d;
    background-color: #e4606d;
  }

  .was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before, input.custom-control-input.parsley-error:focus ~ .custom-control-label::before, textarea.custom-control-input.parsley-error:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
  }

  .was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before, input.custom-control-input.parsley-error:focus:not(:checked) ~ .custom-control-label::before, textarea.custom-control-input.parsley-error:focus:not(:checked) ~ .custom-control-label::before, .was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label, input.custom-file-input.parsley-error ~ .custom-file-label, textarea.custom-file-input.parsley-error ~ .custom-file-label {
    border-color: #ff473d;
  }

  .was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label, input.custom-file-input.parsley-error:focus ~ .custom-file-label, textarea.custom-file-input.parsley-error:focus ~ .custom-file-label {
    border-color: #ff473d;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
  }
}

.dark-themec .btn-light {
  color: #fdfeff;
  background-color: #24243e;
  border-color: #24243e;
}

.dark-theme {
  .btn-light {
    &:hover {
      color: #fdfeff;
      background-color: #dde0e9;
      border-color: #d5d9e4;
    }

    &:focus, &.focus {
      box-shadow: 0 0 0 0.2rem rgba(212, 214, 220, 0.5);
    }

    &.disabled, &:disabled {
      color: #fdfeff;
      background-color: #24243e;
      border-color: #24243e;
    }

    &:not(:disabled):not(.disabled) {
      &:active, &.active {
        color: #fdfeff;
        background-color: #d5d9e4;
        border-color: #cdd2df;
      }
    }
  }

  .show > .btn-light.dropdown-toggle {
    color: #fdfeff;
    background-color: #d5d9e4;
    border-color: #cdd2df;
  }

  .btn-light:not(:disabled):not(.disabled) {
    &:active:focus, &.active:focus {
      box-shadow: 0 0 0 0.2rem rgba(212, 214, 220, 0.5);
    }
  }

  .show > .btn-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(212, 214, 220, 0.5);
  }

  .btn-dark {
    color: $white;
    background-color: #334151;
    border-color: #334151;

    &:hover {
      color: $white;
      background-color: #131212;
      border-color: #131212;
    }

    &:focus {
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0);
    }
  }
}

.btn-dark.focus {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0);
}

.dark-theme {
  .btn-dark:not(:disabled):not(.disabled) {
    &:active, &.active {
      color: $white;
      background-color: #131212;
      border-color: #232b3b;
    }
  }

  .show > .btn-dark.dropdown-toggle {
    color: $white;
    background-color: #131212;
    border-color: #232b3b;
  }

  .btn-dark:not(:disabled):not(.disabled) {
    &:active:focus, &.active:focus {
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0);
    }
  }

  .show > .btn-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0);
  }

  .btn-outline-light {
    color: #fdfeff !important;
    border-color: rgba(255, 255, 255, 0.05);

    &:hover {
      color: #fdfeff !important;
      background-color: rgba(255, 255, 255, 0.05);
      border-color: rgba(255, 255, 255, 0.05);
    }

    &:focus, &.focus {
      box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.06);
    }

    &.disabled, &:disabled {
      color: #fdfeff !important;
      background-color: transparent;
    }

    &:not(:disabled):not(.disabled) {
      &:active, &.active {
        color: #fdfeff;
        background-color: rgba(255, 255, 255, 0.05);
        border-color: rgba(255, 255, 255, 0.05);
      }
    }
  }

  .show > .btn-outline-light.dropdown-toggle {
    color: #fdfeff;
    background-color: rgba(255, 255, 255, 0.05);
    border-color: rgba(255, 255, 255, 0.05);
  }

  .btn-outline-light:not(:disabled):not(.disabled) {
    &:active:focus, &.active:focus {
      box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.06);
    }
  }

  .show > .btn-outline-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.06);
  }

  .btn-outline-dark {
    color: $white !important;
    border-color: #000;

    &:hover {
      color: $white !important;
      background-color: #334151;
      border-color: #334151;
    }

    &:focus, &.focus {
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0);
    }

    &.disabled, &:disabled {
      color: #fdfeff !important;
      background-color: transparent;
    }

    &:not(:disabled):not(.disabled) {
      &:active, &.active {
        color: $white;
        background-color: #334151;
        border-color: #000;
      }
    }
  }

  .show > .btn-outline-dark.dropdown-toggle {
    color: $white;
    background-color: #334151;
    border-color: #000;
  }

  .btn-outline-dark:not(:disabled):not(.disabled) {
    &:active:focus, &.active:focus {
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0);
    }
  }

  .show > .btn-outline-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0);
  }

  .dropdown-menu {
    color: #fdfeff;
    border: 1px solid rgba(255, 255, 255, 0.05);
    background-color: #1c1c38;
    box-shadow: 0 0 0 1px rgba(30, 32, 47, 0.12), 0 8px 16px 0 rgba(30, 32, 47, 0.24);
  }

  .dropdown-divider {
    border-top: 1px solid rgba(255, 255, 255, 0.05);
  }

  .dropdown-item {
    color: #fdfeff;

    &:hover, &:focus {
      color: $white;
      background-color: #24243e;
    }

    &.active, &:active {
      color: $white;
    }

    &.disabled, &:disabled {
      color: #6b6b84;
    }
  }

  .dropdown-header {
    color: #6b6b84;
  }

  .dropdown-item-text {
    color: #fdfeff;
  }

  .input-group-text {
    color: #fdfeff;
    background-color: #252542;
    border: 1px solid rgba(255, 255, 255, 0.05);
  }

  .custom-control-input {
    &:checked ~ .custom-control-label::before {
      color: $white;
    }

    &:focus ~ .custom-control-label::before {
      box-shadow: none;
    }

    &:not(:disabled):active ~ .custom-control-label::before {
      color: $white;
      background-color: #e6ecff;
      border-color: #e6ecff;
    }

    &:disabled ~ .custom-control-label {
      color: #6b6b84;

      &::before {
        background-color: rgba(255, 255, 255, 0.05);
      }
    }
  }

  .custom-control-label::before {
    background-color: #252542;
    border: rgba(255, 255, 255, 0.05) solid 1px;
  }

  .custom-checkbox .custom-control-input:disabled {
    &:checked ~ .custom-control-label::before, &:indeterminate ~ .custom-control-label::before {
      background-color: rgba(135, 96, 251, 0.5);
    }
  }

  .custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before, .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(135, 96, 251, 0.5);
  }

  .custom-select {
    color: $white;
    background-color: #252542;
    border: 1px solid rgba(255, 255, 255, 0.05);

    &:focus {
      box-shadow: none;

      &::-ms-value {
        color: $white;
        background-color: $white;
      }
    }

    &:disabled {
      color: #6b6b84;
      background-color: rgba(255, 255, 255, 0.05);
    }
  }

  .custom-file-input:disabled ~ .custom-file-label {
    background-color: rgba(255, 255, 255, 0.05);
  }

  .custom-file-label {
    color: #fdfeff;
    background-color: #252542;
    border: 1px solid rgba(255, 255, 255, 0.05);

    &::after {
      color: #fdfeff;
      background-color: #252542;
    }
  }

  .custom-range {
    &:focus {
      &::-webkit-slider-thumb, &::-moz-range-thumb, &::-ms-thumb {
        box-shadow: 0 0 0 1px $white, 0 0 0 0.2rem rgba(135, 96, 251, 0.25);
      }
    }

    &::-webkit-slider-thumb:active {
      background-color: #e6ecff;
    }

    &::-webkit-slider-runnable-track {
      background-color: rgba(255, 255, 255, 0.05);
    }

    &::-moz-range-thumb:active {
      background-color: #e6ecff;
    }

    &::-moz-range-track {
      background-color: rgba(255, 255, 255, 0.05);
    }

    &::-ms-thumb:active {
      background-color: #e6ecff;
    }

    &::-ms-fill-lower, &::-ms-fill-upper {
      background-color: rgba(255, 255, 255, 0.05);
    }

    &:disabled {
      &::-webkit-slider-thumb, &::-moz-range-thumb, &::-ms-thumb {
        background-color: #6b6b84;
      }
    }
  }

  .nav-link.disabled {
    color: #6b6b84;
  }

  .nav-tabs {
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);

    .nav-link {
      &:hover, &:focus {
        border-color: rgba(255, 255, 255, 0.05) rgba(255, 255, 255, 0.05) rgba(255, 255, 255, 0.05);
      }

      &.disabled {
        color: #6b6b84;
      }

      &.active {
        color: #fdfeff;
        background-color: $white;
        border-color: rgba(255, 255, 255, 0.05) rgba(255, 255, 255, 0.05) $white;
      }
    }

    .nav-item.show .nav-link {
      color: #fdfeff;
      background-color: $white;
      border-color: rgba(255, 255, 255, 0.05) rgba(255, 255, 255, 0.05) $white;
    }
  }

  .nav-pills {
    .nav-link.active, .show > .nav-link {
      color: $white;
    }
  }

  .navbar-light {
    .navbar-brand {
      color: rgba(0, 0, 0, 0.9);

      &:hover, &:focus {
        color: rgba(0, 0, 0, 0.9);
      }
    }

    .navbar-nav {
      .nav-link {
        color: rgba(0, 0, 0, 0.5);

        &:hover, &:focus {
          color: rgba(0, 0, 0, 0.7);
        }

        &.disabled {
          color: rgba(0, 0, 0, 0.3);
        }
      }

      .show > .nav-link, .active > .nav-link {
        color: rgba(0, 0, 0, 0.9);
      }

      .nav-link {
        &.show, &.active {
          color: rgba(0, 0, 0, 0.9);
        }
      }
    }

    .navbar-toggler {
      color: rgba(0, 0, 0, 0.5);
      border-color: rgba(0, 0, 0, 0.1);
    }

    .navbar-text {
      color: rgba(0, 0, 0, 0.5);

      a {
        color: rgba(0, 0, 0, 0.9);

        &:hover, &:focus {
          color: rgba(0, 0, 0, 0.9);
        }
      }
    }
  }

  .navbar-dark {
    .navbar-brand {
      color: $white;

      &:hover, &:focus {
        color: $white;
      }
    }

    .navbar-nav {
      .nav-link {
        color: rgba(255, 255, 255, 0.5);

        &:hover, &:focus {
          color: rgba(255, 255, 255, 0.75);
        }

        &.disabled {
          color: rgba(255, 255, 255, 0.25);
        }
      }

      .show > .nav-link, .active > .nav-link {
        color: $white;
      }

      .nav-link {
        &.show, &.active {
          color: $white;
        }
      }
    }

    .navbar-toggler {
      color: rgba(255, 255, 255, 0.5);
      border-color: rgba(255, 255, 255, 0.1);
    }

    .navbar-text {
      color: rgba(255, 255, 255, 0.5);

      a {
        color: $white;

        &:hover {
          color: $white;
        }
      }
    }
  }
}

.navbar-dark .navbar-text a:focus {
  color: $white;
}

.dark-theme {
  .card {
    background-color: #1c1c38;
    border: 1px solid rgba(255, 255, 255, 0.05);
  }

  .card-header {
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  }

  .card-footer {
    border-top: 1px solid rgba(255, 255, 255, 0.05);
    background-color: #1c1c38;
  }

  .breadcrumb {
    background-color: rgba(255, 255, 255, 0.05);
  }

  .breadcrumb-item {
    + .breadcrumb-item::before, &.active {
      color: #6b6b84;
    }
  }

  .page-link {
    border: 1px solid rgba(255, 255, 255, 0.05);

    &:hover {
      color: #7c59e6;
      background-color: rgba(255, 255, 255, 0.05);
      border-color: rgba(255, 255, 255, 0.05);
    }

    &:focus {
      box-shadow: 0 0 0 0.2rem rgba(135, 96, 251, 0.25);
    }
  }

  .page-item {
    &.active .page-link {
      color: $white;
    }

    &.disabled .page-link {
      color: #6b6b84;
      background-color: #24243e;
      border-color: rgba(255, 255, 255, 0.05);
    }
  }

  a.badge-light {
    &:hover {
      color: #fdfeff;
      background-color: #d5d9e4;
    }

    &:focus {
      color: #fdfeff;
      background-color: #d5d9e4;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(244, 245, 248, 0.5);
    }

    &.focus {
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(244, 245, 248, 0.5);
    }
  }

  .badge-dark {
    color: $white;
    background-color: #000;
  }

  a.badge-dark {
    &:hover {
      color: $white;
      background-color: #131212;
    }

    &:focus {
      color: $white;
      background-color: #131212;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(59, 72, 99, 0.5);
    }

    &.focus {
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(59, 72, 99, 0.5);
    }
  }

  .jumbotron {
    background-color: rgba(255, 255, 255, 0.05);
  }

  .alert-primary {
    color: #1b3585;
    background-color: #d6e0ff;
    border-color: #c6d4ff;

    hr {
      border-top-color: #adc1ff;
    }

    .alert-link {
      color: #12245b;
    }
  }

  .alert-secondary {
    color: #eb6f33;
    background-color: #f9e5db;
    border-color: #f9e5db;

    hr {
      border-top-color: #cacfdb;
    }

    .alert-link {
      color: #292e37;
    }
  }

  .alert-success {
    color: #1f5c01;
    background-color: #d8efcc;
    border-color: #c8e9b8;

    hr {
      border-top-color: #b9e3a5;
    }

    .alert-link {
      color: #0e2a00;
    }
  }

  .alert-info {
    color: #0c5460;
    background-color: #d1ecf1;
    border-color: #bee5eb;

    hr {
      border-top-color: #abdde5;
    }

    .alert-link {
      color: #062c33;
    }
  }

  .alert-warning {
    color: #856404;
    background-color: $white;
    border-color: #ffeeba;

    hr {
      border-top-color: #ffe8a1;
    }

    .alert-link {
      color: #533f03;
    }
  }

  .alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;

    hr {
      border-top-color: #f1b0b7;
    }

    .alert-link {
      color: #491217;
    }
  }

  .alert-light {
    color: #7f7f81;
    background-color: #fdfdfe;
    border-color: #fcfcfd;

    hr {
      border-top-color: #ededf3;
    }

    .alert-link {
      color: #666667;
    }
  }

  .alert-dark {
    color: #1f2533;
    background-color: #d8dae0;
    border-color: #c8ccd3;

    hr {
      border-top-color: #babfc8;
    }

    .alert-link {
      color: #0c0e13;
    }
  }

  .progress {
    background-color: rgba(255, 255, 255, 0.1);
  }

  .progress-bar {
    color: $white;
  }

  .list-group-item-action {
    color: #fdfeff;

    &:hover, &:focus {
      color: #fdfeff;
      background-color: #24243e;
    }

    &:active {
      color: #334151;
      background-color: rgba(255, 255, 255, 0.05);
    }
  }

  .list-group-item {
    background-color: #1c1c38;
    border: 1px solid rgba(255, 255, 255, 0.05);

    &.disabled, &:disabled {
      color: #6b6b84;
      background-color: #1c1c38;
    }

    &.active {
      color: $white;
      background-color: #33689C;
    }
  }

  .list-group-item-primary {
    color: #1b3585;
    background-color: #c6d4ff;

    &.list-group-item-action {
      &:hover, &:focus {
        color: #1b3585;
        background-color: #adc1ff;
      }

      &.active {
        color: $white;
        background-color: #1b3585;
        border-color: #1b3585;
      }
    }
  }

  .list-group-item-secondary {
    color: #eb6f33;
    background-color: #f9e5db;

    &.list-group-item-action {
      &:hover, &:focus {
        color: #3f4654;
        background-color: #cacfdb;
      }

      &.active {
        color: $white;
        background-color: #3f4654;
        border-color: #3f4654;
      }
    }
  }

  .list-group-item-success {
    color: #1f5c01;
    background-color: #c8e9b8;

    &.list-group-item-action {
      &:hover, &:focus {
        color: #1f5c01;
        background-color: #b9e3a5;
      }

      &.active {
        color: $white;
        background-color: #1f5c01;
        border-color: #1f5c01;
      }
    }
  }

  .list-group-item-info {
    color: #0c5460;
    background-color: #bee5eb;

    &.list-group-item-action {
      &:hover, &:focus {
        color: #0c5460;
        background-color: #abdde5;
      }

      &.active {
        color: $white;
        background-color: #0c5460;
        border-color: #0c5460;
      }
    }
  }

  .list-group-item-warning {
    color: #856404;
    background-color: #ffeeba;

    &.list-group-item-action {
      &:hover, &:focus {
        color: #856404;
        background-color: #ffe8a1;
      }

      &.active {
        color: $white;
        background-color: #856404;
        border-color: #856404;
      }
    }
  }

  .list-group-item-danger {
    color: #721c24;
    background-color: #f5c6cb;

    &.list-group-item-action {
      &:hover, &:focus {
        color: #721c24;
        background-color: #f1b0b7;
      }

      &.active {
        color: $white;
        background-color: #721c24;
        border-color: #721c24;
      }
    }
  }

  .list-group-item-light {
    color: #fdfeff;
    background-color: #24243e;

    &.list-group-item-action {
      &:hover, &:focus {
        color: #fdfeff;
        background-color: rgba(255, 255, 255, 0.05);
      }

      &.active {
        color: $white;
        background-color: #7f7f81;
        border-color: #7f7f81;
      }
    }
  }

  .list-group-item-dark {
    color: $white;
    background-color: #131327;

    &.list-group-item-action {
      &:hover, &:focus {
        color: #1f2533;
        background-color: #babfc8;
      }

      &.active {
        color: $white;
        background-color: #1f2533;
        border-color: #1f2533;
      }
    }
  }

  .close {
    color: #6b6b84;
    text-shadow: 0 1px 0 #1c1c38;

    &:hover {
      color: #334151;
    }
  }

  .toast {
    background-color: rgba(255, 255, 255, 0.85);
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  }

  .toast-header {
    color: #6b6b84;
    background-color: #252542;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }

  .modal-content {
    background-color: #1c1c38 !important;
    border: 1px solid rgba(255, 255, 255, 0.05);
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.12), 0 8px 16px 0 rgba(0, 0, 0, 0.24);
  }

  .modal-backdrop {
    background-color: #334151;
  }

  .modal-header {
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  }

  .modal-footer {
    border-top: 1px solid rgba(255, 255, 255, 0.05);
  }

  .tooltip-inner {
    color: $white;
  }

  .popover {
    background-color: #1c1c38;
    border: 1px solid rgba(255, 255, 255, 0.05);
  }

  .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
    border-top-color: rgba(255, 255, 255, 0.05);
  }

  .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
    border-top-color: #1c1c38;
  }

  .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
    border-right-color: rgba(255, 255, 255, 0.05);
  }

  .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
    border-right-color: #1c1c38;
  }

  .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
    border-bottom-color: rgba(255, 255, 255, 0.05);
  }

  .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
    border-bottom-color: #1c1c38;
  }

  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    border-bottom: 1px solid #f7f7f7;
  }

  .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
    border-left-color: rgba(255, 255, 255, 0.05);
  }

  .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
    border-left-color: #1c1c38;
  }

  .popover-body {
    color: #6b6b84;
  }

  .popover-primary {
    background-color: #33689C;

    &.bs-popover-top .arrow::after, &.bs-popover-auto[x-placement^="top"] .arrow::after {
      border-top-color: #33689C;
    }

    &.bs-popover-bottom .arrow::after, &.bs-popover-auto[x-placement^="bottom"] .arrow::after {
      border-bottom-color: #33689C;
    }

    &.bs-popover-left .arrow::after, &.bs-popover-auto[x-placement^="left"] .arrow::after {
      border-left-color: #33689C;
    }

    &.bs-popover-right .arrow::after, &.bs-popover-auto[x-placement^="right"] .arrow::after {
      border-right-color: #33689C;
    }
  }

  .popover-secondary {
    background-color: #eb6f33;

    &.bs-popover-top .arrow::after, &.bs-popover-auto[x-placement^="top"] .arrow::after {
      border-top-color: #eb6f33;
    }

    &.bs-popover-bottom .arrow::after, &.bs-popover-auto[x-placement^="bottom"] .arrow::after {
      border-bottom-color: #eb6f33;
    }

    &.bs-popover-left .arrow::after, &.bs-popover-auto[x-placement^="left"] .arrow::after {
      border-left-color: #eb6f33;
    }

    &.bs-popover-right .arrow::after, &.bs-popover-auto[x-placement^="right"] .arrow::after {
      border-right-color: #eb6f33;
    }
  }

  .carousel-control-prev, .carousel-control-next {
    color: $white;
  }

  .carousel-control-prev {
    &:hover, &:focus {
      color: $white;
    }
  }
}

.carousel-control-next:hover {
  color: $white;
}

.dark-theme {
  .carousel-control-next:focus {
    color: $white;
  }

  .carousel-indicators li {
    background-color: $white;
  }

  .carousel-caption {
    color: $white;
  }

  .bg-gray-100 {
    background-color: rgba(255, 255, 255, 0.05) !important;
  }

  a.bg-gray-100 {
    &:hover, &:focus {
      background-color: #24243e !important;
    }
  }

  button.bg-gray-100 {
    &:hover, &:focus {
      background-color: #24243e !important;
    }
  }

  .bg-light {
    background-color: rgba(255, 255, 255, 0.05) !important;
  }

  a.bg-light {
    &:hover, &:focus {
      background-color: #24243e !important;
    }
  }

  button.bg-light {
    &:hover, &:focus {
      background-color: #24243e !important;
    }
  }

  .bg-dark {
    background-color: #fdfeff !important;
  }

  a.bg-dark {
    &:hover, &:focus {
      background-color: #131212 !important;
    }
  }

  button.bg-dark {
    &:hover, &:focus {
      background-color: #131212 !important;
    }
  }

  .bg-white {
    background-color: #1c1c38 !important;
  }

  .bg-transparent {
    background-color: transparent !important;
  }

  .border {
    border: 1px solid rgba(255, 255, 255, 0.05) !important;
  }

  .border-top {
    border-top: 1px solid rgba(255, 255, 255, 0.05) !important;
  }

  .border-right {
    border-right: 1px solid rgba(255, 255, 255, 0.05) !important;
  }

  .border-bottom {
    border-bottom: 1px solid rgba(255, 255, 255, 0.05) !important;
  }

  .border-left {
    border-left: 1px solid rgba(255, 255, 255, 0.05) !important;
  }

  .border-light {
    border-color: #24243e !important;
  }

  .border-dark {
    border-color: #fdfeff !important;
  }

  .border-white {
    border-color: $white !important;
  }

  .shadow-sm {
    box-shadow: 0 0.125rem 0.25rem rgba(16, 19, 41, 0.075) !important;
  }

  .shadow {
    box-shadow: 0 0.5rem 1rem rgba(16, 19, 41, 0.15) !important;
  }

  .shadow-lg {
    box-shadow: 0 1rem 3rem rgba(16, 19, 41, 0.175) !important;
  }

  .stretched-link::after {
    background-color: rgba(0, 0, 0, 0);
  }

  .text-white {
    color: $white !important;
  }

  .text-light {
    color: #24243e !important;
  }

  a.text-light {
    &:hover, &:focus {
      color: #c5cbda !important;
    }
  }

  .text-dark {
    color: #fdfeff !important;
  }

  a.text-dark {
    &:hover, &:focus {
      color: #1e2533 !important;
    }
  }

  .text-body {
    color: #000 !important;
  }

  .text-muted {
    color: #6b6b84 !important;
  }

  .text-black-50 {
    color: rgba(0, 0, 0, 0.5) !important;
  }

  .text-white-50 {
    color: rgba(255, 255, 255, 0.5) !important;
  }

  .accordion {
    .card-header a {
      color: #fdfeff;
      background-color: #252542;

      &:hover, &:focus {
        color: #fdfeff;
      }

      &.collapsed {
        color: #fdfeff;
        background-color: #24243e;

        &:hover, &:focus {
          color: #fdfeff;
          background-color: rgba(255, 255, 255, 0.05);
        }
      }
    }

    .card-body {
      background-color: #1c1c38;
      border: 1px solid rgba(255, 255, 255, 0.05);
    }
  }

  .accordion-dark {
    .card {
      border-color: #fdfeff;
    }

    .card-header a {
      color: #fdfeff;

      &:hover, &:focus {
        color: #fdfeff;
      }

      &.collapsed {
        background-color: #fdfeff;
        color: rgba(255, 255, 255, 0.8);

        &:hover, &:focus {
          background-color: #141c2b;
          color: $white;
        }
      }
    }
  }

  .accordion-gray {
    .card {
      border-color: #6b6b84;
    }

    .card-header a {
      color: #6b6b84;

      &:hover, &:focus {
        color: #6b6b84;
      }

      &.collapsed {
        background-color: #6b6b84;
        color: rgba(255, 255, 255, 0.8);

        &:hover, &:focus {
          background-color: #6a7a96;
          color: $white;
        }
      }
    }
  }

  .badge-light {
    background-color: rgba(255, 255, 255, 0.05);
    color: $white;
  }

  .breadcrumb-style1 .breadcrumb-item a, .breadcrumb-style2 .breadcrumb-item a, .breadcrumb-style3 .breadcrumb-item a {
    color: #fdfeff;
  }

  .breadcrumb-style1 .breadcrumb-item + .breadcrumb-item::before, .breadcrumb-style2 .breadcrumb-item + .breadcrumb-item::before, .breadcrumb-style3 .breadcrumb-item + .breadcrumb-item::before {
    color: #6b6b84;
  }

  .btn-light {
    color: #fdfeff;
    background-color: rgba(255, 255, 255, 0.06);
    border-color: rgba(255, 255, 255, 0.06);

    &:hover {
      color: #fdfeff;
      background-color: #24243e;
      border-color: #24243e;
    }

    &:focus, &.focus {
      box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.06);
    }

    &.disabled, &:disabled {
      color: #fdfeff;
      background-color: #24243e;
      border-color: #24243e;
    }

    &:not(:disabled):not(.disabled) {
      &:active, &.active {
        color: #fdfeff;
        background-color: #24243e;
        border-color: #24243e;
      }
    }
  }

  .show > .btn-light.dropdown-toggle {
    color: #fdfeff;
    background-color: #24243e;
    border-color: #24243e;
  }

  .btn-light:not(:disabled):not(.disabled) {
    &:active:focus, &.active:focus {
      box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.06);
    }
  }

  .show > .btn-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.06);
  }

  .card-header, .card-footer {
    border-color: rgba(255, 255, 255, 0.05);
  }

  .card-title {
    color: #fdfeff;
    font-weight: 400;
  }

  .font-weight-bold, h6, .h6 {
    font-weight: 400 !important;
  }

  .form-label {
    color: #fdfeff;
  }

  .modal-backdrop {
    background-color: rgba(0, 0, 0, 0.7);
    opacity: 1.05;
  }

  .modal-title {
    color: #fdfeff;
  }

  .nav-pills .nav-link {
    color: #fdfeff;

    &:hover, &:focus {
      color: #fdfeff;
    }

    &.active {
      border-radius: 2px;

      &:hover, &:focus {
        color: $white;
      }
    }
  }

  .nav-dark .nav-link {
    color: #6b6b84;

    &:hover, &:focus {
      color: $white;
    }
  }

  .nav-tabs .nav-link {
    background-color: rgba(255, 255, 255, 0.03);
    color: #fdfeff;

    &:hover, &:focus {
      background-color: rgba(255, 255, 255, 0.05);
      color: #fdfeff;
    }

    &.active {
      background-color: #1c1c38;
      color: #fdfeff;
    }
  }

  .page-link {
    color: #fdfeff;
    background-color: #252542;
    border: 1px solid rgba(255, 255, 255, 0.05);

    &:hover, &:focus {
      background-color: rgba(255, 255, 255, 0.05);
      box-shadow: none;
    }
  }

  .pagination-dark {
    .page-item.active .page-link {
      color: $white;
    }

    .page-link {
      background-color: rgba(255, 255, 255, 0.05);
      color: rgba(255, 255, 255, 0.8);

      &:hover, &:focus {
        color: $white;
        background-color: rgba(255, 255, 255, 0.08);
      }
    }
  }

  .pagination-success .page-item.active .page-link {
    background-color: #03c895;
    border-color: #03c895;
  }

  .popover-header {
    color: #fdfeff;
    border-color: rgba(255, 255, 255, 0.05);
    background: transparent;
  }

  .popover-head-primary .popover-body, .popover-head-secondary .popover-body {
    border: 1px solid rgba(28, 39, 60, 0.2);
  }

  .popover-primary {
    .popover-header {
      color: $white;
    }

    .popover-body {
      padding: 0;
      color: rgba(255, 255, 255, 0.75);
    }
  }

  .popover-secondary .popover-body {
    padding: 0;
    color: rgba(255, 255, 255, 0.75);
  }

  .popover-head-primary .popover-header {
    background-color: #33689C;
  }

  .popover-head-secondary .popover-header {
    background-color: #eb6f33;
  }

  .table {
    color: #fdfeff;

    thead {
      th, td {
        color: #fdfeff;
      }
    }

    tbody tr {
      background-color: #1c1c38;
    }
  }

  .table-striped tbody tr:nth-of-type(2n+1) {
    background-color: #24243e;
  }

  .table-bordered thead th {
    background-color: rgba(255, 255, 255, 0.05);
  }
}

@media print {
  .dark-theme pre, blockquote {
    border: 1px solid #6b6b84;
  }

  .dark-theme {
    .badge {
      border: 1px solid #000;
    }

    .table {
      td, th {
        background-color: $white !important;
      }
    }

    .table-bordered {
      th, td {
        border: 1px solid rgba(255, 255, 255, 0.05) !important;
      }
    }

    .table-dark {
      th, td, thead th, tbody + tbody {
        border-color: rgba(255, 255, 255, 0.05);
      }
    }

    .table .thead-dark th {
      border-color: rgba(255, 255, 255, 0.05);
    }
  }
}

/*  ----- BOOTSTRAP OVERRIDES  ----- */

/*  ----- 3.1 Accordion  ----- */

/*  ----- 3.2 Alerts  ----- */

/*  ----- 3.3 Buttons  ----- */

/*  ----- 3.5 Forms  ----- */

/*  ----- 3.8 Modal  ----- */

/*  ----- 3.9 Nav  ----- */

/*  ----- 3.10 Pagination  ----- */

/*  ----- 3.11 Popover  ----- */

/*  ----- 3.13 Table  ----- */

.table-bordered thead td {
  background-color: rgba(255, 255, 255, 0.05);
}

.dark-theme {
  .toast {
    background-color: #252542;
    border-color: rgba(255, 255, 255, 0.05);
    box-shadow: 0 2px 3px rgba(28, 39, 60, 0.06);
  }

  .toast-header {
    border-bottom-color: rgba(255, 255, 255, 0.05);

    .close {
      color: #6b6b84;
    }
  }

  table.dataTable {
    border: 1px solid rgba(255, 255, 255, 0.05);

    &.no-footer {
      border-bottom-color: rgba(255, 255, 255, 0.05);
    }

    thead {
      th, td {
        color: #fdfeff;
        border-top: 1px solid rgba(255, 255, 255, 0.05);
      }
    }
  }

  .dataTables_wrapper {
    .dataTables_filter input {
      border: 1px solid rgba(255, 255, 255, 0.05);
    }

    .dataTables_paginate .paginate_button.current {
      color: $white !important;

      &:hover, &:focus {
        color: $white !important;
      }
    }
  }

  .ui-datepicker {
    background-color: #1c1c38;
    border: 1px solid rgba(255, 255, 255, 0.05);
    box-shadow: 0 5px 12px 0 rgb(16, 19, 41);

    .ui-datepicker-header {
      color: #334151;

      .ui-datepicker-next, .ui-datepicker-prev {
        color: #6b6b84;
      }

      .ui-datepicker-next {
        &:hover::before, &:focus::before {
          color: #fdfeff;
        }
      }

      .ui-datepicker-prev {
        &:hover::before, &:focus::before {
          color: #fdfeff;
        }
      }

      .ui-datepicker-next-hover, .ui-datepicker-prev-hover {
        color: #fdfeff;
      }
    }

    .ui-datepicker-title {
      color: #fdfeff;
    }

    .ui-datepicker-calendar {
      th {
        color: #fdfeff;
      }

      td {
        border: 1px solid rgba(255, 255, 255, 0.05);
        background-color: #1c1c38;

        &.ui-datepicker-other-month .ui-state-default {
          color: #6b6b84;
        }

        span {
          color: #fdfeff;
        }

        a {
          color: #fdfeff;

          &:hover {
            background-color: rgba(255, 255, 255, 0.05);
            color: #33689C;
          }
        }
      }

      .ui-datepicker-today a {
        color: $white;

        &:hover, &:focus {
          color: $white;
        }
      }
    }
  }

  .ui-datepicker-inline {
    border-color: rgba(255, 255, 255, 0.05);
  }

  .wizard {
    border: 1px solid rgba(255, 255, 255, 0.05);
    background-color: #1c1c38;

    > {
      .steps {
        a {
          color: #fdfeff;

          &:hover, &:active {
            color: #fdfeff;
          }

          .number, &:hover .number, &:active .number {
            color: #fdfeff;
            background-color: rgba(255, 255, 255, 0.05);
          }
        }

        .current a {
          .number, &:hover .number, &:active .number {
            background-color: #33689C;
            color: $white;
          }
        }

        .disabled a {
          color: #6b6b84;

          &:hover, &:active {
            color: #6b6b84;
          }
        }

        .current a {
          .number, &:hover .number, &:active .number {
            color: $white;
          }
        }

        .done a {
          color: #6b6b84;

          &:hover, &:active {
            color: #6b6b84;
          }

          .number, &:hover .number, &:active .number {
            background-color: #252542;
            border: 1px solid rgba(255, 255, 255, 0.05);
          }
        }
      }

      .content {
        border-top: 1px solid rgba(255, 255, 255, 0.05);
        border-bottom: 1px solid rgba(255, 255, 255, 0.05);
        min-height: 150px;
        padding: 20px;

        > {
          .title {
            color: #fdfeff;
          }

          .body input.parsley-error {
            border-color: #ff473d;
          }
        }
      }

      .actions {
        > ul > li:last-child a {
          background-color: #ff473d;
        }

        a {
          color: $white;
        }
      }
    }
  }
}

/*  ---------- CUSTOM VENDOR STYLES  ---------- */

/*  ----- 4.1 Datatables  ----- */

/*  ----- 4.2 Datepicker  ----- */

/*  ----- 4.3 jQuery Steps  ----- */

/***** DEFAULT STYLE WIZARD *****/

.wizard > .actions a:hover {
  color: $white;
}

.dark-theme {
  .wizard > .actions {
    a:active {
      color: $white;
    }

    .disabled a {
      background-color: #eb6f33;

      &:hover, &:active {
        background-color: #eb6f33;
      }
    }
  }

  .wizard-style-1 > .steps > ul {
    a {
      .number, &:hover .number, &:active .number {
        color: #6b6b84;
        background-color: rgba(255, 255, 255, 0.05);
      }
    }

    .current a {
      .number, &:hover .number, &:active .number {
        color: $white;
      }
    }

    .done a {
      .number, &:hover .number, &:active .number {
        background-color: #643ab0;
        color: $white;
      }
    }
  }

  .wizard-style-2 > .steps > ul {
    a {
      .number, &:hover .number, &:active .number {
        border: 2px solid rgba(255, 255, 255, 0.05);
        color: #6b6b84;
        background-color: $white;
      }
    }

    .done a {
      .number, &:hover .number, &:active .number {
        border-color: #6f42c1;
        color: #6f42c1;
      }
    }
  }

  .parsley-checkbox {
    &.parsley-error {
      border: 1px solid #ff473d;
    }

    &.parsley-success {
      border: 1px solid #03c895;
    }
  }

  .parsley-errors-list {
    color: #ff473d;
  }

  .parsley-style-1 {
    .parsley-input {
      &.parsley-error .form-control {
        border-color: #ff473d;
      }

      &.parsley-success .form-control {
        border-color: #03c895;
      }
    }

    .parsley-checkbox.parsley-error {
      border: 1px solid #ff473d;
    }

    .parsley-errors-list.filled {
      color: #ff473d;
    }
  }

  .jqvmap-label {
    background-color: rgba(17, 17, 17, 0.9);
  }

  .ql-bubble {
    &.ql-container:not(.ql-disabled) a::before {
      background-color: #fdfeff;
    }

    .ql-tooltip {
      background-color: #fdfeff;

      &.ql-flip .ql-tooltip-arrow {
        border-top-color: #fdfeff;
      }

      &:not(.ql-flip) .ql-tooltip-arrow {
        border-bottom-color: #fdfeff;
      }
    }

    .ql-toolbar button {
      color: #8896af;

      .ql-fill {
        fill: #8896af;
      }

      .ql-stroke {
        stroke: #8896af;
      }

      &.ql-active {
        color: $white;
        background-color: rgba(255, 255, 255, 0.08);
      }
    }
  }

  .ql-snow {
    &.ql-container {
      border-color: rgba(255, 255, 255, 0.05);
    }

    &.ql-toolbar {
      border-color: rgba(255, 255, 255, 0.05);

      .ql-picker-label {
        border-color: rgba(255, 255, 255, 0.05);

        &.ql-active {
          background-color: rgba(255, 255, 255, 0.05);
          color: #fdfeff;
        }
      }

      button {
        color: #fdfeff;

        &:hover {
          border-color: rgba(255, 255, 255, 0.05);
        }
      }
    }
  }
}

@media (min-width: 576px) {
  .dark-theme .wizard.vertical > .content {
    border-left: 1px solid rgba(255, 255, 255, 0.05);
  }
}

@media (min-width: 576px) {
  .dark-theme .wizard.vertical > .actions {
    border-left: 1px solid rgba(255, 255, 255, 0.05);
  }
}

/***** CUSTOM STYLES *****/

/*  ----- 4.5 Parsley  ----- */

/*  ----- 4.4 jQVMap  ----- */

/*  ----- 4.7 Quill  ----- */

.ql-snow.ql-toolbar button:focus {
  border-color: rgba(255, 255, 255, 0.05);
}

.dark-theme {
  .ql-snow {
    &.ql-toolbar button {
      &:hover .ql-stroke, &:focus .ql-stroke {
        stroke: #6b6b84;
      }

      &.ql-active {
        background-color: rgba(255, 255, 255, 0.05);
        color: #fdfeff;
        border-color: transparent;

        .ql-fill {
          fill: #fdfeff;
        }

        .ql-stroke {
          stroke: #fdfeff;
        }
      }
    }

    .ql-picker {
      color: #fdfeff;

      &:hover .ql-picker-label, &:focus .ql-picker-label {
        color: #fdfeff;
      }

      &:hover .ql-picker-label .ql-stroke, &:focus .ql-picker-label .ql-stroke {
        stroke: #000;
      }
    }

    .ql-picker-label {
      border: 1px solid rgba(255, 255, 255, 0.05);
      background-color: #252542;
    }

    .ql-stroke {
      stroke: #000;
    }

    .ql-editor {
      padding: 20px;
      color: #fdfeff;
    }
  }

  .irs-slider {
    &:hover:before, &:focus:before {
      background-color: #260bfa;
    }
  }

  .irs-modern .irs-slider {
    background-color: $white;
  }

  .irs-min, .irs-max {
    background: #252542;
    color: #6b6b84;
  }

  .irs-grid-text {
    color: #6b6b84;
  }

  .irs-outline {
    .irs-line-left, .irs-line-mid, .irs-line-right, .irs-bar, .irs-bar-edge {
      height: 10px;
    }

    .irs-slider {
      background-color: $white;
    }
  }

  .ps > .ps__rail-y {
    background-color: rgba(28, 39, 60, 0.04);

    > .ps__thumb-y {
      background-color: #6b6b84;
    }
  }

  .select2-container--default {
    .select2-selection--single {
      background-color: #252542;
      border-color: rgba(255, 255, 255, 0.05);

      .select2-selection__rendered, .select2-selection__placeholder {
        color: #6b6b84;
      }
    }

    .select2-selection--multiple {
      background-color: #252542;
      border-color: rgba(255, 255, 255, 0.05);

      .select2-selection__choice, .select2-selection__choice__remove {
        color: $white;
      }
    }

    &.select2-container--focus .select2-selection--multiple, .select2-search--dropdown .select2-search__field {
      border-color: rgba(255, 255, 255, 0.05);
    }

    .select2-results__option[aria-selected="true"] {
      background-color: #252542;
    }

    &.select2-container--disabled .select2-selection__choice {
      background-color: #6b6b84;
    }
  }

  .select2-container--open {
    .select2-selection--single, .select2-selection--multiple {
      background-color: #252542;
      border-color: rgba(255, 255, 255, 0.05);
    }
  }

  .select2-dropdown {
    border-color: rgba(255, 255, 255, 0.05);
  }

  .bg-gray + .select2-container--default .select2-selection--single {
    background-color: #455473;

    .select2-selection__rendered {
      color: rgba(255, 255, 255, 0.05);
    }
  }

  .sp-container {
    background-color: #252542;
    border-color: rgba(255, 255, 255, 0.05);

    button {
      background-color: rgba(255, 255, 255, 0.05);

      &:hover, &:focus {
        background-color: rgba(255, 255, 255, 0.08);
      }
    }
  }

  .sp-button-container .sp-cancel {
    font-size: 12px;
    color: #6b6b84 !important;

    &:hover, &:focus {
      color: #fdfeff !important;
    }
  }

  .sp-replacer {
    border-color: rgba(255, 255, 255, 0.05);
    background-color: #252542;

    &:hover, &:focus {
      border-color: rgba(255, 255, 255, 0.08);
    }

    &.sp-active {
      border-color: #6b6b84;
    }
  }

  .sp-dd::before {
    color: #6b6b84;
  }

  .sp-choose {
    background-color: rgba(255, 255, 255, 0.05);
  }

  .sp-palette .sp-thumb-el {
    &:hover, &.sp-thumb-active {
      border-color: #fdfeff;
    }
  }

  .datetimepicker {
    border-color: rgba(255, 255, 255, 0.05);

    table {
      th {
        &.prev, &.next, &.switch {
          background-color: $white;
          color: #334151;
        }

        &.prev span::before, &.next span::before {
          color: #fdfeff;
        }

        &.prev {
          &:hover, &:focus {
            background-color: $white;
          }
        }

        &.next {
          &:hover, &:focus {
            background-color: $white;
          }
        }

        &.switch {
          color: #fdfeff;

          &:hover, &:focus {
            background-color: $white;
          }
        }
      }

      td {
        &.old, &.new {
          color: #6b6b84;
        }

        &.active {
          color: $white;

          &:hover, &:focus {
            color: $white;
          }
        }
      }

      span.active {
        color: $white;

        &:hover, &:focus {
          color: $white;
        }
      }
    }

    .datetimepicker-days table thead tr:last-child th {
      color: #fdfeff;
    }
  }

  .main-datetimepicker {
    border-color: rgba(255, 255, 255, 0.05);

    > .datepicker_inner_container {
      background-color: $white;

      > {
        .datepicker_calendar {
          th {
            color: #fdfeff;
          }

          td {
            &.day_another_month {
              color: #6b6b84;
            }

            &.hover {
              background-color: rgba(255, 255, 255, 0.05);
            }

            &.active {
              color: $white;
            }
          }
        }

        .datepicker_timelist {
          border-left: 1px solid #6b6b84;

          > div.timelist_item {
            &:hover, &:focus {
              background-color: rgba(255, 255, 255, 0.05);
            }

            &.active {
              color: $white;
            }
          }
        }
      }
    }
  }

  .main-accordion {
    .accordion-item {
      background-color: #24243e;
      border: 1px solid #dee2ef;

      &:hover, &:focus {
        background-color: #f1f2f6;
        border-color: #bcc4de;
      }

      &.active {
        background-color: $white;
        border-color: #bcc4de;
      }
    }

    .accordion-title {
      color: #334151;
    }
  }

  .ckbox span:before {
    background-color: #252542;
    border: 1px solid rgba(255, 255, 255, 0.05);
  }

  .rdiobox span:before {
    border: 1px solid rgba(255, 255, 255, 0.05);
    background-color: #252542;
  }

  select {
    background: #252542;
    border: 1px solid rgba(255, 255, 255, 0.05);
    color: $white;
  }

  .main-toggle {
    background-color: #2c2c44;

    span {
      &::before, &::after {
        color: $white;
      }
    }
  }

  .main-toggle-secondary.on {
    background-color: #eb6f33;
  }

  .main-toggle-success.on {
    background-color: #03c895;
  }

  .main-toggle-dark.on {
    background-color: #000;
  }

  .main-toggle span {
    background-color: $dark-theme;
  }

  .main-form-group {
    border: 1px solid rgba(255, 255, 255, 0.05);

    &.focus {
      border-color: #6b6b84;
      box-shadow: 0 0 0 2px rgba(91, 71, 251, 0.16);
    }

    .form-label {
      color: #6b6b84;
    }

    .form-control {
      color: #fdfeff;
    }
  }

  .main-img-user {
    &::after {
      background-color: #6b6b84;
      box-shadow: 0 0 0 2px rgba(16, 19, 41, 0.95);
    }

    &.online::after {
      background-color: #03c895;
    }
  }

  .main-avatar {
    color: $white;

    &::after {
      background-color: #6b6b84;
      box-shadow: 0 0 0 2px rgba(16, 19, 41, 0.95);
    }

    &.online::after {
      background-color: #03c895;
    }
  }

  .avatar-xl::after {
    box-shadow: 0 0 0 2.5px rgba(16, 19, 41, 0.95);
  }

  .avatar-xxl::after {
    box-shadow: 0 0 0 3px rgba(16, 19, 41, 0.95);
  }

  .main-list-item {
    + .main-list-item {
      border-top: 1px solid rgba(255, 255, 255, 0.05);
    }

    > div {
      &:first-child h6 {
        color: #fdfeff;
      }

      span {
        color: #6b6b84;
      }
    }
  }

  .main-nav .nav-link {
    color: #fdfeff;

    &:hover, &:focus {
      color: #fdfeff;
    }
  }

  .main-nav-column .nav-link {
    color: #fdfeff;

    i:not([class*=' tx-']) {
      color: #6b6b84;
    }

    &:hover, &:focus, &:hover i:not([class*=' tx-']), &:focus i:not([class*=' tx-']) {
      color: #fdfeff;
    }
  }

  .main-nav-dark .nav-link {
    &:hover, &:focus {
      color: $white;
    }

    + .nav-link {
      border-color: #fdfeff;
    }
  }

  .main-nav-colored-bg .nav-link {
    + .nav-link {
      border-color: rgba(255, 255, 255, 0.4);
    }

    &.active {
      color: $white;
    }
  }

  .main-nav-line {
    .nav-link {
      color: #fdfeff;

      &:hover, &:focus, &.active {
        color: #fdfeff;
      }
    }

    &.main-nav-dark .nav-link {
      color: rgba(255, 255, 255, 0.7);

      &:hover, &:focus {
        color: $white;
      }

      &.active {
        color: $white;

        &::before {
          background-color: $white;
        }
      }
    }
  }

  .main-nav-tabs {
    background-color: #242444;

    .lSAction > a {
      background-color: #6b6b84;

      &:hover, &:focus {
        background-color: #1c1c38;
      }

      &.disabled {
        background-color: rgba(255, 255, 255, 0.05);
        color: $white;
      }
    }

    .tab-link {
      color: #fdfeff;
      background-color: #242444;

      &:hover, &:focus {
        background-color: #24243e;
      }

      &.active {
        background-color: #1c1c38;
        color: #fdfeff;
      }
    }
  }

  .main-content-breadcrumb {
    color: #6b6b84;

    span {
      + span::before {
        color: #6b6b84;
      }

      &:last-child {
        color: #fdfeff;
      }
    }
  }

  .main-content-title, .main-content-label, .card-table-two .card-title, .card-dashboard-eight .card-title {
    color: #fdfeff;
  }

  .main-content-label-sm, .main-content-text {
    color: #6b6b84;
  }

  .main-footer {
    background-color: #1c1c38;
    border-top: 1px solid rgba(255, 255, 255, 0.05);
  }

  .main-table-reference {
    background-color: #1c1c38;

    > {
      thead > tr > {
        th, td {
          border: 1px solid rgba(255, 255, 255, 0.05);
        }
      }

      tbody > tr > {
        th, td {
          border: 1px solid rgba(255, 255, 255, 0.05);
        }
      }

      thead > tr > {
        th, td {
          background-color: #21213e;
          color: #6b6b84;
        }
      }
    }
  }

  .main-header {
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
    background-color: #1c1c38;
  }

  .main-header-menu-icon span {
    background-color: #fdfeff;

    &::before, &::after {
      background-color: #fdfeff;
    }
  }

  .main-header-menu {
    background-color: $white;

    .nav-item {
      &::before {
        border-top: 2px solid rgba(28, 39, 60, 0.12);
        border-left: 2px solid rgba(28, 39, 60, 0.12);
        background-color: $white;
      }

      > .nav-link {
        color: #334151;
      }
    }
  }

  .main-header-menu-header {
    border-bottom: 1px solid rgba(28, 39, 60, 0.05);
  }

  .main-menu-sub .nav-link {
    color: #fdfeff;

    + .nav-link {
      border-top: 1px dotted rgba(255, 255, 255, 0.05);
    }
  }

  .main-menu-sub-mega {
    .nav + .nav {
      border-top: 1px dotted rgba(255, 255, 255, 0.05);
    }

    .mega-subtitle {
      color: #fdfeff;
    }
  }

  .main-header-center {
    .form-control {
      border-color: rgba(255, 255, 255, 0.07);
      background-color: #252542;

      &:focus {
        background-color: #21213c;
        border-color: rgba(255, 255, 255, 0.08);
        color: $white;

        + .btn {
          color: #6b6b84;
        }
      }
    }

    .sp-container .form-control:focus + button {
      color: #6b6b84;
    }
  }

  .sp-container .main-header-center .form-control:focus + button {
    color: #6b6b84;
  }

  .main-header-center {
    .btn, .sp-container button {
      color: #6b6b84;
    }
  }

  .sp-container .main-header-center button {
    color: #6b6b84;
  }

  .main-header-center {
    .btn:hover, .sp-container button:hover {
      color: #fdfeff;
    }
  }

  .sp-container .main-header-center button:hover {
    color: #fdfeff;
  }

  .main-header-center {
    .btn:focus, .sp-container button:focus {
      color: #fdfeff;
    }
  }

  .sp-container .main-header-center button:focus {
    color: #fdfeff;
  }

  .main-header-right {
    .btn-social {
      color: #6b6b84;

      &:hover, &:focus {
        color: #fdfeff;
      }
    }

    .btn-buy {
      color: $white;
    }
  }

  .main-header-search-link {
    color: #fdfeff;
  }

  .main-header-arrow {
    color: #fdfeff;

    &:hover, &:focus {
      color: #fdfeff;
    }
  }

  .main-header-message > a, .main-header-notification > a {
    color: #fdfeff;
  }

  .main-header-message > a.new::before {
    background-color: #ff473d;
  }

  .main-header-notification {
    > a.new::before {
      background-color: #ff473d;
    }

    .dropdown-menu {
      background-color: #242444;
      box-shadow: 0 5px 12px 0 rgb(31, 33, 51);
    }

    .dropdown-footer {
      border-top: 1px solid rgba(255, 255, 255, 0.05);
    }
  }

  .main-notification-title {
    color: #fdfeff;
  }

  .main-notification-list, .main-profile-menu .dropdown-menu .dropdown-item:first-child {
    border-top: 1px solid rgba(255, 255, 255, 0.05);
  }

  .main-notification-list {
    .media {
      + .media {
        border-top: 1px solid rgba(255, 255, 255, 0.05);
      }

      &.new {
        color: #fdfeff;
      }

      &:hover::before, &:focus::before {
        background-color: #24243e;
        border-top: 1px solid rgba(255, 255, 255, 0.05);
        border-bottom: 1px solid rgba(255, 255, 255, 0.05);
      }
    }

    .media-body span {
      color: #6b6b84;
    }
  }

  .main-profile-menu {
    .dropdown-menu {
      border-color: rgba(255, 255, 255, 0.05);
      background-color: #242444;
      box-shadow: 0 5px 12px 0 rgb(31, 33, 51);
    }

    .dropdown-item {
      color: #d6d6e4;

      + .dropdown-item {
        border-top: 1px solid rgba(255, 255, 255, 0.05);
      }
    }
  }

  .main-header-profile h6 {
    color: #fdfeff;
  }

  .main-iconbar {
    background-color: $white;
    background-color: $white;
    border-right: 1px solid rgba(255, 255, 255, 0.05);
    border-top: 1px solid rgba(255, 255, 255, 0.05);

    .nav-link {
      color: #334151;

      &:hover {
        color: #fdfeff;
      }
    }
  }
}

/*  ----- 4.8 RangeSlider  ----- */

/*  ----- ----- ----- OUTLINE SKIN  ----- ----- ----- */

/*  ----- 4.9 Perfect Scrollbar  ----- */

/*  ----- 4.10 Select2  ----- */

/*  ----- 4.11 Spectrum  ----- */

/*  ----- 4.12 Datetimepicker  ----- */

/* Amaze UI Datetimepicker */

/* jQuery Simple Datetimepicker */

/*  ---------- CUSTOM ELEMENT STYLES  ---------- */

/*  ----- 5.3 Forms    ----- */

/* Checkbox */

/* Radio Box */

/*  ----- 5.4 Image    ----- */

/*  ----- 5.5 List    ----- */

/*  ----- 5.7 Nav    ----- */

/*  --------- PAGE LAYOUT STYLES  ----------*/

/*  ----- 6.1 Content   ----- */
@media (max-width: 991.98px) {
  .dark-theme .main-content-left-show .main-content-left {
    background-color: $white;
    border-right: 1px solid rgba(255, 255, 255, 0.05);
    box-shadow: 0 0 3px rgba(28, 39, 60, 0.1);
  }
}

/*  ----- 6.2 Footer   ----- */

/*  ----- 6.3 Global   ----- */

/*  ----- 6.4 Header   ----- */

@media (min-width: 992px) {
  .dark-theme .main-menu-sub {
    border: 2px solid rgba(28, 39, 60, 0.12);
  }
}

@media (min-width: 992px) {
  .dark-theme .main-menu-sub-mega .container {
    background-color: $white;
    border: 2px solid rgba(28, 39, 60, 0.12);
  }
}

@media (min-width: 992px) {
  .dark-theme .main-menu-sub-mega .container > div + div {
    border-left: 1px solid rgba(28, 39, 60, 0.05);
  }
}

/*  ----- 6.5 Iconbar   ----- */

.main-iconbar .nav-link:focus {
  color: #fdfeff;
}

.dark-theme {
  .main-iconbar-logo {
    color: #fdfeff;

    &:hover, &:focus {
      color: #fdfeff;
    }
  }

  .main-iconbar-help {
    color: #6b6b84;

    &:hover, &:focus {
      color: #fdfeff;
    }
  }

  .main-iconbar-aside {
    background-color: $white;
    border-right: 1px solid rgba(255, 255, 255, 0.05);
    border-top: 1px solid rgba(255, 255, 255, 0.05);
  }

  .main-iconbar-toggle-menu {
    color: #fdfeff;

    &:hover, &:focus {
      color: #fdfeff;
    }
  }

  .main-iconbar-body {
    .nav-item + .nav-item {
      border-top: 1px dotted rgba(255, 255, 255, 0.05);
    }

    .nav-link {
      color: #334151;

      i, &.with-sub::after {
        color: #6b6b84;
      }
    }

    .nav-sub {
      border-top: 1px dotted rgba(255, 255, 255, 0.05);

      .nav-sub-item + .nav-sub-item {
        border-top: 1px dotted rgba(255, 255, 255, 0.05);
      }

      .nav-sub-link {
        color: #334151;
      }
    }
  }

  .main-iconbar-title {
    color: #fdfeff;
  }

  .main-iconbar-text {
    color: #6b6b84;
  }

  .main-navbar-backdrop {
    background-color: rgba(0, 0, 0, 0.45);
  }

  .main-navbar {
    background-color: #1c1c38;

    .nav-label {
      color: #6b6b84;
    }

    .nav-link {
      color: #fdfeff;

      &.with-sub {
        &::after {
          color: #6b6b84;
        }

        &::before {
          border-top-color: rgba(255, 255, 255, 0.1);
          border-left-color: rgba(255, 255, 255, 0.1);
          background-color: #1c1c38;
        }
      }
    }

    .nav-sub {
      border: 1px solid rgba(255, 255, 255, 0.05);
      background-color: #1c1c38;
      box-shadow: 0 5px 12px 0 rgb(16, 19, 41);
    }

    .nav-sub-link {
      color: #fdfeff;

      &.with-sub::after {
        color: #6b6b84;
      }

      &:hover, &:focus {
        color: #33689C;
      }
    }

    .nav-sub-mega {
      border-top: 1px dotted rgba(255, 255, 255, 0.05);

      .nav li:not(.nav-sub-item) {
        color: #fdfeff;
      }
    }
  }

  .main-navbar-header {
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  }

  .main-navbar-search {
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
    background: #252542;

    .form-control:focus {
      border-color: rgba(255, 255, 255, 0.05);
    }
  }

  .main-navbar-three .nav-link {
    color: #fdfeff;

    &:hover, &:focus {
      color: #fdfeff;
    }
  }

  .main-sidebar {
    border-right: 0 solid rgba(255, 255, 255, 0.05);
  }

  .main-sidebar-sticky {
    background: $white;
    box-shadow: 0 8px 24px rgba(61, 119, 180, 0.12);
    border-right: 1px solid #e1e6f1;
  }

  .main-sidebar-loggedin .media-body {
    h6 {
      color: #fdfeff;
    }

    span {
      color: #6b6b84;
    }
  }

  .main-sidebar-body {
    .nav-label {
      color: #6b6b84;
    }

    .nav-link {
      color: #fdfeff;
    }

    .nav-sub .nav-sub-link:before {
      color: #6b6b84;
    }

    .nav-sub-link {
      color: #bfbfe0;

      &.with-sub::after {
        color: rgba(255, 255, 255, 0.05);
      }

      + .nav-sub-link {
        border-top: 1px dotted rgba(255, 255, 255, 0.05);
      }

      &:hover, &:focus {
        color: #33689C;
      }
    }
  }

  .main-home-slider {
    background-color: #1904be;
  }

  .main-home-content {
    color: rgba(255, 255, 255, 0.7);

    .main-logo, h1 {
      color: $white;
    }

    nav:last-child a {
      color: rgba(255, 255, 255, 0.5);

      &:hover, &:focus {
        color: $white;
      }
    }
  }

  .main-content-choose-demo {
    background-color: #0f0373;
    color: rgba(255, 255, 255, 0.5);

    .title {
      color: $white;
    }

    .card {
      figcaption {
        background-color: rgba(25, 4, 190, 0.75);
      }

      &.coming-soon {
        color: #fdfeff;
        background-color: rgba(255, 255, 255, 0.05);
      }
    }

    .main-content-label, .card-table-two .card-title {
      color: #6b6b84;
    }
  }

  .card-table-two .main-content-choose-demo .card-title, .main-content-choose-demo .card-dashboard-eight .card-title, .card-dashboard-eight .main-content-choose-demo .card-title {
    color: #6b6b84;
  }

  .main-content-choose-demo .main-content-title {
    color: $white;
  }

  .main-footer-demo {
    background-color: #0c025b;
    color: rgba(255, 255, 255, 0.4);

    a {
      color: rgba(255, 255, 255, 0.5);

      &:hover, &:focus {
        color: $white;
      }
    }
  }

  .main-icon-group, .modal-content-demo .modal-body h6 {
    color: #fdfeff;
  }

  .tooltip-static-demo, .popover-static-demo {
    background-color: rgba(255, 255, 255, 0.05);
  }

  .main-dropdown-form-demo {
    .static-dropdown {
      background-color: rgba(255, 255, 255, 0.05);
    }

    .dropdown-title {
      color: #fdfeff;
    }
  }

  .ql-wrapper-demo {
    background-color: $white;
  }

  .ql-scrolling-demo {
    background-color: #252542;
    border: 1px solid rgba(255, 255, 255, 0.05);

    .ql-container .ql-editor {
      color: #fdfeff;
    }
  }

  .main-content-left-components {
    border-right: 1px solid rgba(255, 255, 255, 0.05);

    .component-item .nav-link {
      color: #8b8ba7;
    }
  }

  .main-breadcrumbs .breadcrumb-item {
    a {
      color: #334151;

      &:hover {
        color: #6b6b84;
      }
    }

    + .breadcrumb-item::before {
      color: #6b6b84;
    }
  }

  .main-page-header {
    border-bottom: 1px solid #dee2ef;
  }

  .main-page-content-list li i {
    color: #6b6b84;
  }

  .main-syntax-wrapper pre {
    border-color: rgba(255, 255, 255, 0.05);
    background-color: $white;
  }

  .main-datepicker .ui-datepicker .ui-datepicker-calendar {
    th {
      color: #fdfeff;

      &.ui-datepicker-week-end:first-child:not(.ui-datepicker-other-month) a {
        color: #ff473d;
      }
    }

    td {
      &.ui-datepicker-week-end:first-child:not(.ui-datepicker-other-month) a {
        color: #ff473d;
      }

      span, a {
        color: #fdfeff;
      }
    }

    .ui-datepicker-today a {
      color: $white;
    }
  }

  .main-nav-calendar-event .nav-link.exclude {
    color: #6b6b84;

    i {
      color: #6b6b84;
    }

    &::before {
      border-top: 1px solid #6b6b84;
    }
  }

  .main-calendar {
    border-color: rgba(255, 255, 255, 0.05);

    .fc-content, .fc-divider, .fc-list-heading td, .fc-list-view, .fc-popover, .fc-row, tbody, td {
      border-color: rgba(255, 255, 255, 0.05);
    }
  }
}

@media (min-width: 1200px) {
  .dark-theme .main-iconbar-toggle-menu::before {
    border-left: 2px solid #fdfeff;
  }
}

/*  ----- 6.6 Navbar   ----- */

@media (min-width: 992px) {
  .dark-theme .main-navbar {
    box-shadow: 0 5px 12px 0 rgb(16, 19, 41);
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  }

  .main-navbar.sticky.sticky-pin {
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  }
}

@media (min-width: 992px) {
  .dark-theme .main-navbar .nav-sub .container {
    box-shadow: 0 5px 12px 0 rgb(16, 19, 41);
  }
}

@media (min-width: 992px) {
  .dark-theme {
    .main-navbar .nav-sub-mega {
      .container, .container-fluid {
        border: 1px solid transparent;
      }

      .container, .container-fluid {
        border: 1px solid rgba(255, 255, 255, 0.05);
        background-color: #1c1c38;
        box-shadow: 0 5px 12px 0 rgb(16, 19, 41);
      }
    }

    .nav-item-mega .nav-sub.nav-sub-mega {
      background: none;
      box-shadow: none;
      border: 0;
    }
  }
}

@media (min-width: 992px) {
  .dark-theme .main-navbar .nav-sub-mega {
    .container > div + div, .container-fluid > div + div {
      border-left: 1px solid rgba(255, 255, 255, 0.05);
    }
  }
}

/*  ----- 6.7 Navbar Variant   ----- */

@media (min-width: 992px) {
  .dark-theme .main-navbar-three .nav-sub {
    box-shadow: 0 0 0 2px rgba(180, 189, 206, 0.5);
  }
}

/*  ----- 6.8 Sidebar   ----- */

@media (max-width: 991.98px) {
  .dark-theme .main-sidebar {
    background-color: $white;
  }
}

/*  ----- -----# TEMPLATE STYLES  ----- -----# */

/*  ----- 7.1 Demo   ----- */

/*  ----- 7.2 Calendar   ----- */

.main-calendar th {
  border-color: rgba(255, 255, 255, 0.05);
}

.dark-theme {
  .main-calendar {
    thead {
      border-color: rgba(255, 255, 255, 0.05);
    }

    .fc-header-toolbar {
      h2 {
        color: #fdfeff;
      }

      button {
        background-color: #252542;
        border: 1px solid rgba(255, 255, 255, 0.05);
        color: #fdfeff;

        &:hover, &:focus, &.fc-state-active {
          background-color: rgba(255, 255, 255, 0.05);
        }

        &.fc-today-button {
          color: $white;

          &.fc-state-disabled {
            border-color: rgba(255, 255, 255, 0.05);
            background-color: #24243e;
            color: #6b6b84;
          }
        }
      }
    }
  }

  .fc-title {
    color: #334151;
  }

  .main-calendar {
    .fc-head-container .fc-day-header {
      color: #fdfeff;
    }

    .fc-view {
      > table {
        background-color: #1c1c38;
        border: 1px solid rgba(255, 255, 255, 0.05);

        > {
          thead {
            th, td {
              border-color: rgba(255, 255, 255, 0.05);
            }
          }

          tbody > tr > td {
            border-color: rgba(255, 255, 255, 0.05);
          }
        }
      }

      .fc-other-month {
        background-color: #21213b;
      }

      .fc-day-number {
        color: #fdfeff;

        &:hover, &:focus {
          color: #fdfeff;
          background-color: #24243e;
        }
      }

      &.fc-agenda-view .fc-day-header > a > span:first-child, &.fc-listMonth-view .fc-list-heading-main, &.fc-listWeek-view .fc-list-heading-main {
        color: #6b6b84;
      }

      &.fc-listMonth-view .fc-list-heading-main span:last-child, &.fc-listWeek-view .fc-list-heading-main span:last-child {
        color: #fdfeff;
      }

      &.fc-listMonth-view .fc-list-item, &.fc-listWeek-view .fc-list-item {
        background-color: #252542;
      }

      &.fc-listMonth-view .fc-list-item > td.fc-list-item-time, &.fc-listWeek-view .fc-list-item > td.fc-list-item-time {
        border-top: 1px solid rgba(255, 255, 255, 0.05);
        border-right: 1px solid rgba(255, 255, 255, 0.05);
      }

      &.fc-listMonth-view .fc-list-item > td.fc-list-item-title, &.fc-listWeek-view .fc-list-item > td.fc-list-item-title {
        border-bottom: 1px solid rgba(255, 255, 255, 0.05);
        border-right: 1px solid rgba(255, 255, 255, 0.05);
      }

      &.fc-listMonth-view .fc-list-item-title a, &.fc-listWeek-view .fc-list-item-title a {
        color: #fdfeff;
      }
    }

    td.fc-today {
      background-color: #24243e;

      .fc-day-number {
        color: $white;

        &:hover, &:focus {
          color: $white;
        }
      }
    }
  }

  .fc-unthemed {
    .fc-divider, .fc-list-heading td, .fc-popover .fc-header {
      background: #21213b;
    }
  }

  .main-calendar {
    .fc-time-grid-event .fc-desc {
      color: rgba(28, 39, 60, 0.8);
    }

    .fc-list-empty {
      background-color: $white;
      border: 1px solid rgba(255, 255, 255, 0.05);
    }
  }

  .main-modal-calendar-schedule {
    .modal-content > .close {
      color: #6b6b84;
    }

    .form-group-date {
      border: 1px solid rgba(255, 255, 255, 0.05);
      background-color: #24243e;
      color: #fdfeff;
    }
  }

  .main-modal-calendar-event {
    .nav-modal-event .nav-link {
      color: rgba(255, 255, 255, 0.75);

      &:hover, &:focus {
        color: $white;
      }
    }

    .event-title {
      color: $white;
    }

    .modal-body {
      background-color: #1c1c38;
    }
  }

  .event-desc.tx-gray-900 {
    color: #6b6b84;
  }

  .main-modal-calendar-event {
    .event-start-date, .event-end-date {
      color: #fdfeff;
    }
  }

  .main-nav-line-chat {
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);

    .nav-link {
      color: #fdfeff;
    }
  }

  .main-chat-contacts-wrapper {
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  }

  .main-chat-contacts-more {
    color: $white;
  }

  .main-chat-list {
    .media {
      + .media {
        border-top: 1px solid rgba(255, 255, 255, 0.05);
      }

      &.new {
        background-color: #1c1c38;

        .media-contact-name span:first-child {
          color: #fdfeff;
        }

        .media-body p {
          color: #6b6b84;
        }
      }

      &:hover, &:focus {
        cursor: pointer;
        background-color: #24243e;
        border-top-color: rgba(255, 255, 255, 0.05);
        border-bottom-color: rgba(255, 255, 255, 0.05);
      }

      &:hover:first-child, &:focus:first-child {
        border-top-color: transparent;
      }

      &.selected {
        background-color: #24243e;
        border-top-color: rgba(255, 255, 255, 0.05);
        border-bottom-color: rgba(255, 255, 255, 0.05);

        &:first-child {
          border-top-color: transparent;
        }

        .media-contact-name span:first-child {
          color: #fdfeff;
        }

        .media-body p {
          color: #6b6b84;
        }
      }
    }

    .main-img-user span {
      color: $white;
      background-color: #ff473d;
      box-shadow: 0 0 0 2px #22223d;
    }

    .media-body p {
      color: #6b6b84;
    }

    .media-contact-name span {
      &:first-child {
        color: #fdfeff;
      }

      &:last-child {
        color: #6b6b84;
      }
    }
  }

  .main-chat-header {
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);

    .nav-link {
      color: #6b6b84;
    }
  }

  .main-chat-msg-name {
    h6 {
      color: #fdfeff;
    }

    small {
      color: #6b6b84;
    }
  }

  .main-chat-body {
    .media.flex-row-reverse .main-msg-wrapper {
      color: $white;
    }

    .media-body > div:last-child {
      color: #6b6b84;
    }
  }

  .main-chat-time span {
    background: #2b2b4c;
  }

  .main-msg-wrapper {
    background-color: rgba(255, 255, 255, 0.05);
  }

  .main-chat-footer {
    border-top: 1px solid rgba(255, 255, 255, 0.05);
    background-color: #1c1c38;

    .nav-link {
      color: #6b6b84;
    }
  }

  .main-contact-label {
    color: #6b6b84;

    &::after {
      border-bottom: 1px solid rgba(255, 255, 255, 0.05);
    }
  }

  .main-contact-item {
    + .main-contact-item {
      border-top: 1px solid rgba(255, 255, 255, 0.05);
    }

    &:hover, &:focus {
      background-color: #24243e;
      border-top-color: rgba(255, 255, 255, 0.05);
      border-bottom-color: rgba(255, 255, 255, 0.05);
    }

    &.selected {
      border-left-color: rgba(255, 255, 255, 0.05);
      border-top-color: rgba(255, 255, 255, 0.05);
      border-bottom-color: rgba(255, 255, 255, 0.05);
      background-color: #24243e;
    }
  }

  .main-contact-body {
    h6 {
      color: #fdfeff;
    }

    span {
      color: #6b6b84;
    }
  }

  .main-contact-star {
    color: #6b6b84;

    &:hover, &:focus {
      color: #6b6b84;
    }

    &.active {
      color: #ffc107;
    }
  }

  .main-contact-info-header {
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);

    .main-img-user a {
      background-color: #252542;
      color: #6b6b84;
      box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.05);

      &:hover, &:focus {
        color: $white;
      }
    }

    .media-body {
      h4, p {
        color: #fdfeff;
      }

      .nav-link {
        color: #6b6b84;
      }
    }
  }

  .main-contact-action a {
    color: #6b6b84;
  }

  .main-contact-info-body {
    .media-icon {
      color: #6b6b84;
    }

    .media-body {
      label {
        color: #6b6b84;
      }

      span {
        color: #fdfeff;
      }
    }
  }

  .main-invoice-list {
    border-top: 1px solid rgba(255, 255, 255, 0.05);

    .media {
      border: 1px solid transparent;

      &:hover, &:focus {
        background-color: #24243e;
      }

      + .media::before {
        border-top: 1px dotted rgba(255, 255, 255, 0.05);
      }
    }

    .media-icon {
      color: #6b6b84;
    }

    .media-body {
      h6 {
        color: #fdfeff;
      }

      p {
        color: #fdfeff;

        span {
          color: #6b6b84;
        }
      }
    }

    .selected {
      background-color: #24243e;
      border-top-color: rgba(255, 255, 255, 0.05);
      border-bottom-color: rgba(255, 255, 255, 0.05);
    }
  }

  .invoice-title {
    color: rgba(255, 255, 255, 0.05);
  }

  .billed-from h6, .billed-to h6 {
    color: #fdfeff;
  }

  .invoice-info-row {
    + .invoice-info-row {
      border-top: 1px dotted rgba(255, 255, 255, 0.05);
    }

    span:first-child {
      color: #fdfeff;
    }
  }

  .table-invoice thead > tr > th {
    color: #8b8ba7;
  }
}

@media (max-width: 575px) {
  .dark-theme .main-calendar .fc-header-toolbar button {
    &.fc-month-button::before, &.fc-agendaWeek-button::before, &.fc-agendaDay-button::before, &.fc-listWeek-button::before, &.fc-listMonth-button::before {
      color: #334151;
    }
  }
}

@media (min-width: 992px) {
  .dark-theme .main-content-left-chat {
    border-right: 1px solid rgba(255, 255, 255, 0.05);
  }
}

/*  ----- 7.4 Contacts   ----- */
@media (min-width: 992px) {
  .dark-theme .main-content-left-contacts {
    border-right: 1px solid rgba(255, 255, 255, 0.05);
  }
}

/*  ----- 7.5 Invoice   ----- */
@media (min-width: 992px) {
  .dark-theme .main-content-left-invoice {
    border-right: 1px solid rgba(255, 255, 255, 0.05);
  }
}

.table-invoice thead > tr > td {
  color: #8b8ba7;
}

.dark-theme {
  .table-invoice tbody > tr > {
    th:first-child, td:first-child {
      color: #fdfeff;
    }

    th:first-child .invoice-notes p, td:first-child .invoice-notes p {
      color: #8b8ba7;
    }
  }

  .main-mail-header .btn-group .btn {
    border-color: rgba(255, 255, 255, 0.05);
    background-color: #252542;
  }
}

/*  ----- 7.6 Mail   ----- */
@media (min-width: 992px) {
  .dark-theme .main-content-left-mail {
    border-right: 1px solid rgba(255, 255, 255, 0.05);
  }
}

.main-mail-header .btn-group .sp-container button {
  border-color: rgba(255, 255, 255, 0.05);
  background-color: #252542;
}

.dark-theme {
  .sp-container .main-mail-header .btn-group button {
    border-color: rgba(255, 255, 255, 0.05);
    background-color: #252542;
  }

  .main-mail-header .btn-group {
    .btn:hover, .sp-container button:hover {
      color: #fdfeff;
      background-color: #24243e;
    }
  }

  .sp-container .main-mail-header .btn-group button:hover {
    color: #fdfeff;
    background-color: #24243e;
  }

  .main-mail-header .btn-group {
    .btn:focus, .sp-container button:focus {
      color: #fdfeff;
      background-color: #24243e;
    }
  }

  .sp-container .main-mail-header .btn-group button:focus {
    color: #fdfeff;
    background-color: #24243e;
  }

  .main-mail-header .btn-group {
    .btn.disabled, .sp-container button.disabled {
      background-color: rgba(255, 255, 255, 0.06);
      color: rgba(255, 255, 255, 0.1);
      border-color: rgba(255, 255, 255, 0.05);
    }
  }

  .sp-container .main-mail-header .btn-group button.disabled {
    background-color: rgba(255, 255, 255, 0.06);
    color: rgba(255, 255, 255, 0.1);
    border-color: rgba(255, 255, 255, 0.05);
  }

  .main-mail-options {
    border-top: 1px solid rgba(255, 255, 255, 0.05);
    border-bottom: 0;

    .btn:hover, .sp-container button:hover {
      background-color: rgba(255, 255, 255, 0.05);
    }
  }

  .sp-container .main-mail-options button:hover {
    background-color: rgba(255, 255, 255, 0.05);
  }

  .main-mail-options {
    .btn:focus, .sp-container button:focus {
      background-color: rgba(255, 255, 255, 0.05);
    }
  }

  .sp-container .main-mail-options button:focus {
    background-color: rgba(255, 255, 255, 0.05);
  }

  .main-mail-options {
    .btn.disabled, .sp-container button.disabled {
      color: #6b6b84;
    }
  }

  .sp-container .main-mail-options button.disabled {
    color: #6b6b84;
  }

  .main-mail-list {
    border-top: 1px solid transparent;
  }

  .main-mail-item {
    border-top: 1px solid rgba(255, 255, 255, 0.05);
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
    background-color: #24243e;

    &:hover, &:focus {
      background-color: #24243e;
    }

    &.unread {
      background-color: #1c1c38;
    }
  }

  .main-mail-star {
    color: rgba(255, 255, 255, 0.05);

    &.active {
      color: #ffc107;
    }
  }

  .main-mail-subject {
    strong {
      color: #fdfeff;
    }

    span {
      color: #6b6b84;
    }
  }

  .main-mail-date {
    color: #6b6b84;
  }

  .main-mail-compose {
    background-color: rgba(0, 0, 0, 0.8);
  }

  .main-mail-compose-box {
    box-shadow: 0 5px 12px 0 rgb(16, 19, 41);
    border: 1px oslid rgba(255, 255, 255, 0.1);
  }

  .main-mail-compose-header {
    background-color: #252542;
    color: #fdfeff;
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);

    .nav-link {
      color: #6b6b84;

      &:hover, &:focus {
        color: #fdfeff;
      }
    }
  }

  .main-mail-compose-body {
    background-color: #252542;

    .form-group {
      .form-label {
        color: #fdfeff;
      }

      + .form-group {
        border-top: 1px solid rgba(255, 255, 255, 0.05);
      }

      .nav-link {
        color: #8b8ba7;

        &:hover, &:focus {
          color: #fdfeff;
        }
      }
    }
  }

  .main-mail-two .main-header {
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  }

  .main-mail-left {
    background-color: #f9f9f9;
    border-right: 1px solid #6b6b84;
  }

  .main-mail-content {
    background-color: #f4f5fb;
  }

  .main-content-left-profile {
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  }

  .main-profile-name {
    color: #fdfeff;
  }

  .main-profile-name-text {
    color: #6b6b84;
  }

  .main-content-body-profile {
    .nav {
      border-bottom: 1px solid rgba(255, 255, 255, 0.05);
    }

    .main-nav-line .nav-link {
      color: #fdfeff;
    }
  }

  .main-profile-view-info {
    h6 {
      color: #fdfeff;
    }

    span {
      color: #f10075;
    }
  }

  .main-traffic-detail-item > div:first-child > span {
    &:first-child {
      color: #6b6b84;
    }

    &:last-child {
      color: #fdfeff;

      span {
        color: #6b6b84;
      }
    }
  }

  .main-profile-work-list {
    .media-logo {
      color: $white;
    }

    .media-body {
      h6 {
        color: #fdfeff;
      }

      p {
        color: #6b6b84;
      }
    }
  }

  .main-profile-contact-list .media-body {
    span {
      color: #6b6b84;
    }

    div {
      color: #fdfeff;
    }
  }

  .main-card-signin {
    background-color: rgba(255, 255, 255, 0.5);
  }

  .main-signin-header {
    h4 {
      color: #fdfeff;
    }

    label {
      color: #6b6b84;
    }

    .form-control {
      color: #fdfeff;
      border-color: rgba(255, 255, 255, 0.05);

      &:focus {
        border-color: #6b6b84;
      }

      &::placeholder {
        color: #6b6b84;
      }
    }
  }

  .main-signin-footer {
    p {
      color: #6b6b84;
    }

    a {
      color: #fdfeff;
    }
  }

  .main-signup-wrapper {
    background-color: rgba(244, 245, 248, 0.2);
  }

  .main-column-signup-left h5 {
    color: #fdfeff;
  }

  .main-column-signup {
    background-color: $white;
  }

  .main-signup-header {
    h4 {
      color: #fdfeff;
    }

    label {
      color: #6b6b84;
    }

    .form-control {
      color: #fdfeff;
      border-color: rgba(255, 255, 255, 0.05);

      &:focus {
        border-color: #6b6b84;
      }

      &::placeholder {
        color: #6b6b84;
      }
    }

    .row > div:first-child {
      .btn, .sp-container button {
        background-color: #4267b2;
        color: $white;
      }
    }
  }

  .sp-container .main-signup-header .row > div:first-child button {
    background-color: #4267b2;
    color: $white;
  }

  .main-signup-header .row > div:first-child {
    .btn:hover, .sp-container button:hover {
      background-color: #375694;
      color: $white;
    }
  }

  .sp-container .main-signup-header .row > div:first-child button:hover {
    background-color: #375694;
    color: $white;
  }

  .main-signup-header .row > div:first-child {
    .btn:focus, .sp-container button:focus {
      background-color: #375694;
      color: $white;
    }
  }

  .sp-container .main-signup-header .row > div:first-child button:focus {
    background-color: #375694;
    color: $white;
  }

  .main-signup-footer {
    p {
      color: #6b6b84;
    }

    a {
      color: #fdfeff;
    }
  }

  .main-error-wrapper {
    h1, h2 {
      color: #fdfeff;
    }
  }

  .main-dashboard-title {
    color: #fdfeff;
  }

  .main-dashboard-date {
    .date > div {
      &:first-child {
        color: #fdfeff;
      }

      &:last-child span {
        color: #fdfeff;

        &:last-child {
          color: #6b6b84;
        }
      }
    }

    > i {
      color: #6b6b84;
    }
  }

  .main-dashboard-nav {
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);

    .nav-link + .nav-link {
      border-left: 1px solid rgba(255, 255, 255, 0.05);
    }

    .nav {
      &:first-child .nav-link, &:last-child .nav-link {
        color: #fdfeff;
      }
    }
  }

  .card-dashboard-one {
    border-color: rgba(255, 255, 255, 0.05);

    .card-header {
      .card-title {
        color: #fdfeff;
      }

      .btn-group {
        .btn, .sp-container button {
          background-color: $white;
          color: #6b6b84;
          border: 1px solid rgba(255, 255, 255, 0.05);
        }
      }
    }
  }

  .sp-container .card-dashboard-one .card-header .btn-group button {
    background-color: $white;
    color: #6b6b84;
    border: 1px solid rgba(255, 255, 255, 0.05);
  }

  .card-dashboard-one .card-header .btn-group {
    .btn:hover, .sp-container button:hover {
      background-color: #24243e;
      border-color: rgba(255, 255, 255, 0.05);
    }
  }

  .sp-container .card-dashboard-one .card-header .btn-group button:hover {
    background-color: #24243e;
    border-color: rgba(255, 255, 255, 0.05);
  }

  .card-dashboard-one .card-header .btn-group {
    .btn:focus, .sp-container button:focus {
      background-color: #24243e;
      border-color: rgba(255, 255, 255, 0.05);
    }
  }

  .sp-container .card-dashboard-one .card-header .btn-group button:focus {
    background-color: #24243e;
    border-color: rgba(255, 255, 255, 0.05);
  }

  .card-dashboard-one .card-header .btn-group {
    .btn.active, .sp-container button.active {
      color: #fdfeff;
      background-color: rgba(255, 255, 255, 0.05);
      border-color: rgba(255, 255, 255, 0.05);
    }
  }

  .sp-container .card-dashboard-one .card-header .btn-group button.active {
    color: #fdfeff;
    background-color: rgba(255, 255, 255, 0.05);
    border-color: rgba(255, 255, 255, 0.05);
  }

  .card-dashboard-one {
    .card-body .flot-chart {
      .flot-y-axis > div {
        text-shadow: 1px 1px rgba(255, 255, 255, 0.75);
        color: #334151;
      }

      .flot-x-axis > div {
        color: #6b6b84;
      }
    }

    .card-body-top h2 {
      color: #fdfeff;
    }
  }

  .card-dashboard-two {
    border-color: rgba(255, 255, 255, 0.05);
    background-color: rgba(255, 255, 255, 0.5);

    .card-header h6 {
      color: #fdfeff;

      small {
        color: #6b6b84;
      }
    }
  }

  .card-dashboard-three {
    border-color: rgba(255, 255, 255, 0.05);
    background-color: rgba(255, 255, 255, 0.5);

    .card-header {
      h6, p {
        color: #fdfeff;
      }

      > small {
        color: #6b6b84;
      }
    }
  }

  .card-dashboard-pageviews, .card-dashboard-four {
    border-color: rgba(255, 255, 255, 0.05);

    .card-title {
      color: #fdfeff;
    }
  }

  .card-dashboard-five {
    border-color: rgba(255, 255, 255, 0.05);

    .card-title, .card-body h4 {
      color: #fdfeff;
    }
  }

  .card-table-one {
    border-color: rgba(255, 255, 255, 0.05);

    .table {
      thead tr > {
        th strong, td strong {
          color: #fdfeff;
        }
      }

      tbody tr > {
        th strong, td strong {
          color: #fdfeff;
        }
      }
    }
  }

  .main-content-dashboard-two {
    background-color: #fdfdfd;
  }

  .main-dashboard-header-right > div h5 {
    color: #fdfeff;
  }

  .main-star i {
    color: #6b6b84;

    &.active {
      color: #ffc107;
    }
  }

  .card-dashboard-seven {
    border-color: rgba(255, 255, 255, 0.05);

    .card-header {
      border-bottom: 1px solid rgba(255, 255, 255, 0.05);
      background-color: #24243e;

      .media > div:first-child {
        color: $white;
        background-color: #6b6b84;
      }

      label, .date a {
        color: #6b6b84;
      }
    }

    .card-body {
      .row > div + div::before {
        border-left: 1px dotted rgba(255, 255, 255, 0.05);
      }

      .main-content-label, .card-table-two .card-title {
        color: #334151;
      }
    }
  }

  .card-table-two .card-dashboard-seven .card-body .card-title, .card-dashboard-seven .card-body .card-dashboard-eight .card-title, .card-dashboard-eight .card-dashboard-seven .card-body .card-title {
    color: #334151;
  }

  .card-dashboard-seven .card-body {
    h2 {
      color: #fdfeff;

      span {
        color: #334151;
      }
    }

    .desc {
      span strong {
        color: #fdfeff;
      }

      &.up > i {
        color: #03c895;
      }

      &.down > i {
        color: #ff473d;
      }
    }
  }

  .card-dashboard-six, .card-dashboard-map-one, .card-table-two {
    border-color: rgba(255, 255, 255, 0.05);
  }

  .card-dashboard-eight {
    border-color: rgba(255, 255, 255, 0.05);
    background-color: rgba(255, 255, 255, 0.5);

    .list-group-item {
      border-color: rgba(255, 255, 255, 0.05);

      span {
        color: #fdfeff;
      }
    }
  }

  .bd-transparent {
    border-color: transparent;
  }

  .bd {
    border: 1px solid rgba(255, 255, 255, 0.1);
  }

  .bd-t {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }

  .bd-r {
    border-right: 1px solid rgba(255, 255, 255, 0.1);
  }

  .bd-b {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }

  .bd-l {
    border-left: 1px solid rgba(255, 255, 255, 0.1);
  }

  .bd-y {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }

  .bd-x {
    border-left: 1px solid rgba(255, 255, 255, 0.1);
    border-right: 1px solid rgba(255, 255, 255, 0.1);
  }

  .shadow-base {
    box-shadow: 0 0 0 1px rgb(16, 19, 41), 0 8px 16px 0 rgb(16, 19, 41);
  }

  .shadow-1 {
    box-shadow: 0 0 7px 7px rgba(28, 39, 60, 0.1);
  }

  .shadow-2 {
    box-shadow: 0 0 30px rgba(28, 39, 60, 0.08);
  }

  .header-brand-img.desktop-logo {
    &.theme-logo {
      display: block !important;
    }

    display: none;
  }

  .sidebar {
    background-color: #212140;
    box-shadow: 0 5px 12px 0 rgb(31, 33, 51);
    border-left: 1px solid rgba(255, 255, 255, 0.1);
  }

  .sidebar-icon a:hover {
    color: $white !important;
  }

  .select2-dropdown {
    background-color: #252542;
  }

  .select2-container--default {
    .select2-search--dropdown .select2-search__field {
      border: 1px solid rgba(255, 255, 255, 0.05);
      background: #000;
      color: $white;
      border-radius: 0;
    }

    .select2-selection--single .select2-selection__arrow b {
      border-color: #6b6b84 transparent transparent transparent;
    }
  }

  .ms-choice {
    border: 1px solid rgba(255, 255, 255, 0.05);
    background: #252542;
    color: #6b6b84;

    > {
      span.placeholder {
        color: #6b6b84;
      }

      div.icon-caret {
        border-color: #6b6b84 transparent transparent transparent;
      }
    }
  }

  .ms-drop {
    border: 1px solid rgba(255, 255, 255, 0.05);
    background: #252542;
    color: #fdfeff;
  }

  .ms-search input {
    border: 1px solid rgba(255, 255, 255, 0.05);
    background: #000;
    color: $white;
  }

  .expansion-label {
    color: #6b6b84;
  }

  table.dataTable.dtr-inline.collapsed > tbody > tr[role="row"] > {
    td:first-child:before, th:first-child:before {
      border: 2px solid rgba(16, 19, 41, 0.95);
    }
  }

  div.dt-button-info {
    background-color: $dark-theme;
    border: 2px solid rgba(255, 255, 255, 0.05);
    box-shadow: 3px 3px 8px rgba(16, 19, 41, 0.3);

    h2 {
      border-bottom: 1px solid rgba(255, 255, 255, 0.05);
      background-color: $dark-theme;
    }
  }

  .token.entity {
    background: hsl(240, 33%, 16%);
  }

  .breadcrumb-style1, .breadcrumb-style2, .breadcrumb-style3 {
    border-radius: 0;
    background-color: transparent;
  }

  .btn, .sp-container button {
    color: $white;
  }

  .btn-outline-warning {
    color: $white !important;
  }

  .bg-gray-300.nav-bg {
    background-color: rgba(255, 255, 255, 0.05) !important;
  }

  .main-nav-line .nav-link.active::before {
    background-color: #33689C;
  }

  .nav-bg .nav-tabs {
    border-bottom: 0;
  }

  .bootstrap-tagsinput {
    background-color: #1c1c38;
  }

  .carousel-inner.bg-dark {
    background-color: #3b3e4e !important;
  }

  .timeline__content {
    background-color: #252542;
    box-shadow: 0 5px 12px 0 rgb(16, 19, 41);
    color: #fdfeff;
    border: 1px solid rgba(255, 255, 255, 0.05);
  }

  .timeline--horizontal {
    .timeline__item .timeline__content {
      &:after {
        border-top: 10px solid #252542;
      }

      &:before {
        border-top: 12px solid rgba(255, 255, 255, 0.1);
      }
    }

    .timeline__item--bottom .timeline__content {
      &:before {
        border-bottom: 12px solid rgba(255, 255, 255, 0.1);
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        border-top: none;
        bottom: 100%;
        top: auto;
      }

      &:after {
        border-bottom: 10px solid #252542;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: none;
        bottom: 100%;
        top: auto;
      }
    }

    .timeline-divider {
      background: rgba(255, 255, 255, 0.1);
    }
  }

  .timeline__item:after {
    background-color: $dark-theme;
    border: 4px solid #252542;
  }

  .timeline-nav-button {
    background-color: #252542;
    border: 1px solid rgba(255, 255, 255, 0.05);
	outline: 0;
  }

  .timeline:not(.timeline--horizontal):before {
    background: rgba(255, 255, 255, 0.1);
  }

  .timeline--mobile .timeline__item .timeline__content {
    &:before {
      border-right: 12px solid rgba(255, 255, 255, 0.1);
    }

    &:after {
      border-right: 10px solid #252542;
    }
  }

  .sweet-alert {
    background-color: #1c1c38;
    box-shadow: 0 0.5rem 1rem rgba(16, 19, 41, 0.15);
    border: 1px solid rgba(255, 255, 255, 0.05);

    h2 {
      color: #fdfeff;
    }

    p {
      color: #6b6b84;
    }
  }

  .productdesc .nav-tabs {
    border-bottom: 0;
  }

  .sp-picker-container {
    border-left: solid 1px rgba(255, 255, 255, 0.05);
  }

  .iti__selected-flag {
    border-right: 1px solid rgba(255, 255, 255, 0.05);
	outline: 0;
  }

  .iti--allow-dropdown .iti__flag-container:hover .iti__selected-flag {
    background-color: rgba(255, 255, 255, 0.07);
  }

  .iti__country-list {
    background-color: #1c1c38;
    box-shadow: 0 5px 12px 0 rgb(16, 19, 41);
    border: solid 1px rgba(255, 255, 255, 0.05);
  }

  .iti__country.iti__highlight {
    background-color: #24243e;
  }

  .iti__divider {
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  }

  .select2-container--default {
    &.select2-container--disabled {
      .select2-selection--single, .select2-selection--multiple {
        background-color: #27273e;
      }
    }

    .select2-selection--multiple .select2-selection__choice {
      border: 1px solid rgba(255, 255, 255, 0.05);
    }
  }

  .daterangepicker {
    &.dropdown-menu {
      box-shadow: 0 5px 12px 0 rgb(16, 19, 41);
      border: 1px solid rgba(255, 255, 255, 0.05);
    }

    .calendar-table {
      border: 1px solid rgba(255, 255, 255, 0.05);
      background-color: #1c1c38;
    }

    .input-mini {
      border: 1px solid rgba(255, 255, 255, 0.05);

      &.active {
        border: 1px solid rgba(255, 255, 255, 0.08);
      }
    }

    .calendar td {
      color: #6b6b84 !important;
    }

    td {
      &.active {
        background-color: #24243e;
        border-color: transparent;
        color: $white !important;

        &:hover {
          background-color: #24243e;
          border-color: transparent;
          color: $white !important;
        }
      }

      &.off {
        background-color: #24243e;

        &.in-range, &.start-date, &.end-date {
          background-color: #24243e;
        }
      }

      &.in-range, &.available:hover {
        background-color: #24243e;
      }
    }

    th.available:hover {
      background-color: #24243e;
    }

    &:after {
      border-bottom: 6px solid #1c1c38;
    }

    &:before {
      border-bottom: 7px solid rgba(255, 255, 255, 0.1);
    }
  }

  .SumoSelect {
    > .CaptionCont {
      border: 1px solid rgba(255, 255, 255, 0.05);
      color: #6b6b84;
      background-color: #252542;
    }

    &:focus > .CaptionCont {
      border: 1px solid rgba(255, 255, 255, 0.07);
    }

    &.open > .optWrapper {
      box-shadow: 0 5px 12px 0 rgb(16, 19, 41);
      border: 1px solid rgba(255, 255, 255, 0.05);
      background: #1c1c38;
    }

    > .optWrapper > .options li.opt {
      border-bottom: 1px solid rgba(255, 255, 255, 0.05);

      &:hover {
        background-color: #24243e;
      }
    }

    &.disabled > .CaptionCont {
      border-color: #27273e;
    }

    > .optWrapper.multiple > .options li.opt span i {
      border: 1px solid rgba(255, 255, 255, 0.05);
      background-color: #252542;
    }

    .select-all {
      > span i {
        border: 1px solid rgba(255, 255, 255, 0.05);
        background-color: #252542;
      }

      border-bottom: 1px solid rgba(255, 255, 255, 0.05);
      background-color: #1c1c38;
    }

    > .optWrapper {
      > .MultiControls {
        border-top: 1px solid rgba(255, 255, 255, 0.05);
        background-color: #252542;
      }

      &.multiple > .MultiControls > p {
        &.btnOk {
          border-right: 1px solid rgba(255, 255, 255, 0.05);
        }

        &:hover {
          background-color: #24243e;
        }
      }
    }
  }

  .dropify-wrapper {
    background-color: #252542;
    border: 1px solid rgba(255, 255, 255, 0.05);
    color: #6b6b84;

    .dropify-preview {
      background-color: #252542;
    }
  }

  .ff_fileupload_wrap .ff_fileupload_dropzone {
    border: 2px dashed rgba(255, 255, 255, 0.05);
    background-color: #252542;

    &:hover, &:focus, &:active {
      background-color: #24243e;
      border: 1px solid rgba(255, 255, 255, 0.05);
    }
  }

  .note-popover .popover-content, .card-header.note-toolbar {
    background: #252542;
  }

  .note-editor.note-frame .note-editing-area .note-editable {
    background: #252542;
    color: #fdfeff;
  }

  .note-placeholder {
    color: #fdfeff;
  }

  .note-btn-group .btn {
    color: #fdfeff !important;
  }

  .note-editor.note-frame .note-statusbar {
    background-color: #252542;
    border-top: 1px solid rgba(255, 255, 255, 0.05);
  }

  .ql-snow .ql-tooltip {
    background-color: #1c1c38;
    border: 1px solid rgba(255, 255, 255, 0.05);
    box-shadow: 0px 0px 5px rgb(16, 19, 41);
    color: #6b6b84;

    input[type=text] {
      border: 1px solid #13172b;
      background: #000;
      color: $white;
    }
  }

  .morris-wrapper-demo text {
    fill: #6b6b84;
  }

  .main-content-body-profile {
    .table {
      td, tbody + tbody {
        border-top: 0;
      }
    }

    p {
      color: #6b6b84;
    }
  }

  .bg-gray-100 {
    background-color: #494983;
  }

  .bg-gray-200 {
    background-color: #404072;
  }

  .bg-gray-300 {
    background-color: #373762;
  }

  .bg-gray-400 {
    background-color: #2e2e52;
  }

  .bg-gray-500 {
    background-color: #252542;
  }

  .bg-gray-600 {
    background-color: #1c1c31;
  }

  .bg-gray-700 {
    background-color: #121221;
  }

  .bg-gray-800 {
    background-color: #090910;
  }

  .bg-gray-900 {
    background-color: #000000;
  }

  .side-header {
    box-shadow: 0 5px 12px 0 rgb(16, 19, 41);
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  }

  .main-sidebar-sticky {
    background: #1c1c38;
    box-shadow: 0 5px 12px 0 rgb(16, 19, 41);
    border-right: 1px solid rgba(255, 255, 255, 0.05);
  }

  .main-sidebar-body {
    border-top: 1px solid rgba(255, 255, 255, 0.05);
  }

  &.main-sidebar-hide {
    .side-menu .main-logo {
      .icon-logo {
        display: none;
      }

      .desktop-logo.theme-logo {
        display: none !important;
      }

      .theme-logo.icon-logo {
        display: block !important;
      }
    }

    &.main-sidebar-open .side-menu .main-logo {
      .theme-logo.icon-logo {
        display: none !important;
      }

      .desktop-logo {
        display: none !important;

        &.theme-logo {
          display: block !important;
        }
      }
    }
  }

  .main-navbar-search .form-control {
    border: 1px solid transparent;
  }

  &.color-horizontal .main-navbar-search {
    background: #33689C;
  }

  &.light-horizontal .main-navbar-search {
    background: $white;
    border-bottom: 1px solid #e1e6f1;

    .form-control {
      background: $white;
    }

    .btn {
      color: #000;
      opacity: 0.5;
    }
  }

  &.gradient-horizontal .main-navbar-search {
    background: linear-gradient (310.6deg, (123, 86, 232) 6.8%, (123, 86, 232) 57.8%);
  }

  .main-header.hor-header .dropdown-menu {
    margin-top: 0;
  }

  .table td {
    font-weight: 300;
  }

  .main-navbar .nav-item .nav-sub li:not(.nav-sub-item) {
    color: $white;
  }

  .main-msg-wrapper {
    &:before, &:after {
      border-color: transparent #282842 transparent transparent;
    }
  }

  .main-chat-body .media.flex-row-reverse .main-msg-wrapper {
    background-color: rgba(135, 96, 251, 0.08);

    &:before, &:after {
      border-left-color: rgba(135, 96, 251, 0.08);
    }
  }

  .main-content-left-components .component-item .nav-link:hover {
    color: #33689C;
  }

  .dropify-wrapper:hover {
    background-image: linear-gradient(-45deg, #181c3a 25%, transparent 25%, transparent 50%, #181c3a 50%, #181c3a 75%, transparent 75%, transparent);
  }

  .bd-primary {
    border-color: #33689C;
  }

  .bd-secondary {
    border-color: #eb6f33;
  }

  .bd-success {
    border-color: #03c895;
  }

  .bd-warning {
    border-color: #ffc107;
  }

  .bd-danger {
    border-color: #ff473d;
  }

  .bd-info {
    border-color: #01b8ff;
  }

  .bd-white {
    border-color: $white;
  }

  .bd-gray-100 {
    border-color: #f1f2f9;
  }

  .bd-gray-200, .bd-gray-300 {
    border-color: #e1e6f1;
  }

  .bd-gray-400 {
    border-color: #b4bdce;
  }

  .bd-gray-500, .bd-gray-600 {
    border-color: #99a6b7;
  }

  .bd-gray-700 {
    border-color: #596882;
  }

  .bd-gray-800 {
    border-color: #3b4863;
  }

  .bd-gray-900 {
    border-color: #334151;
  }

  .bd-white-1 {
    border-color: rgba(255, 255, 255, 0.1);
  }

  .bd-white-2 {
    border-color: rgba(255, 255, 255, 0.2);
  }

  .bd-white-3 {
    border-color: rgba(255, 255, 255, 0.3);
  }

  .bd-white-4 {
    border-color: rgba(255, 255, 255, 0.4);
  }

  .bd-white-5 {
    border-color: rgba(255, 255, 255, 0.5);
  }

  .bd-white-6 {
    border-color: rgba(255, 255, 255, 0.6);
  }

  .bd-white-7 {
    border-color: rgba(255, 255, 255, 0.7);
  }

  .bd-white-8 {
    border-color: rgba(255, 255, 255, 0.8);
  }

  .productdesc .bg-light {
    background-color: rgba(255, 255, 255, 0.05) !important;
  }
}

/*  ----- 7.7 Mail Two   ----- */

/*  ----- 7.8 Profile   ----- */

@media (min-width: 992px) {
  .dark-theme .main-content-left-profile {
    border-right: 1px solid rgba(255, 255, 255, 0.05);
  }
}

/*  ----- 7.9 Signin   ----- */

@media (min-width: 480px) {
  .dark-theme .main-card-signin {
    border: 1px solid rgba(255, 255, 255, 0.05);
  }
}

/*  ----- 7.10 Signup   ----- */

@media (min-width: 992px) {
  .dark-theme .main-column-signup {
    border-left: 1px solid rgba(255, 255, 255, 0.05);
  }
}

/*  ----- 7.11 Error   ----- */

/*  ----- -----# DASHBOARD STYLES  ----- -----# */
/*  ----- 8.1 Dashboard One   ----- */

/*  ----- 8.2 Dashboard Two   ----- */

/*  ----- -----# UTILITIES/HELPER CLASSES  ----- -----# */
/*  ----- 9.2 Border    ----- */

@media (min-width: 480px) {
  .dark-theme {
    .bd-xs {
      border: 1px solid rgba(255, 255, 255, 0.2);
    }

    .bd-xs-t {
      border-top: 1px solid rgba(255, 255, 255, 0.1);
    }

    .bd-xs-r {
      border-right: 1px solid rgba(255, 255, 255, 0.1);
    }

    .bd-xs-b {
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }

    .bd-xs-l {
      border-left: 1px solid rgba(255, 255, 255, 0.1);
    }

    .bd-xs-y {
      border-top: 1px solid rgba(255, 255, 255, 0.1);
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }

    .bd-xs-x {
      border-left: 1px solid rgba(255, 255, 255, 0.1);
      border-right: 1px solid rgba(255, 255, 255, 0.1);
    }
  }
}

@media (min-width: 576px) {
  .dark-theme {
    .bd-sm {
      border: 1px solid rgba(255, 255, 255, 0.1);
    }

    .bd-sm-t {
      border-top: 1px solid rgba(255, 255, 255, 0.1);
    }

    .bd-sm-r {
      border-right: 1px solid rgba(255, 255, 255, 0.1);
    }

    .bd-sm-b {
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }

    .bd-sm-l {
      border-left: 1px solid rgba(255, 255, 255, 0.1);
    }

    .bd-sm-y {
      border-top: 1px solid rgba(255, 255, 255, 0.1);
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }

    .bd-sm-x {
      border-left: 1px solid rgba(255, 255, 255, 0.1);
      border-right: 1px solid rgba(255, 255, 255, 0.1);
    }
  }
}

@media (min-width: 768px) {
  .dark-theme {
    .bd-md {
      border: 1px solid rgba(255, 255, 255, 0.1);
    }

    .bd-md-t {
      border-top: 1px solid rgba(255, 255, 255, 0.1);
    }

    .bd-md-r {
      border-right: 1px solid rgba(255, 255, 255, 0.1);
    }

    .bd-md-b {
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }

    .bd-md-l {
      border-left: 1px solid rgba(255, 255, 255, 0.1);
    }

    .bd-md-y {
      border-top: 1px solid rgba(255, 255, 255, 0.1);
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }

    .bd-md-x {
      border-left: 1px solid rgba(255, 255, 255, 0.1);
      border-right: 1px solid rgba(255, 255, 255, 0.1);
    }
  }
}

@media (min-width: 992px) {
  .dark-theme {
    .bd-lg {
      border: 1px solid rgba(255, 255, 255, 0.1);
    }

    .bd-lg-t {
      border-top: 1px solid rgba(255, 255, 255, 0.1);
    }

    .bd-lg-r {
      border-right: 1px solid rgba(255, 255, 255, 0.1);
    }

    .bd-lg-b {
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }

    .bd-lg-l {
      border-left: 1px solid rgba(255, 255, 255, 0.1);
    }

    .bd-lg-y {
      border-top: 1px solid rgba(255, 255, 255, 0.1);
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }

    .bd-lg-x {
      border-left: 1px solid rgba(255, 255, 255, 0.1);
      border-right: 1px solid rgba(255, 255, 255, 0.1);
    }
  }
}

@media (min-width: 1200px) {
  .dark-theme {
    .bd-xl {
      border: 1px solid rgba(255, 255, 255, 0.1);
    }

    .bd-xl-t {
      border-top: 1px solid rgba(255, 255, 255, 0.1);
    }

    .bd-xl-r {
      border-right: 1px solid rgba(255, 255, 255, 0.1);
    }

    .bd-xl-b {
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }

    .bd-xl-l {
      border-left: 1px solid rgba(255, 255, 255, 0.1);
    }

    .bd-xl-y {
      border-top: 1px solid rgba(255, 255, 255, 0.1);
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }

    .bd-xl-x {
      border-left: 1px solid rgba(255, 255, 255, 0.1);
      border-right: 1px solid rgba(255, 255, 255, 0.1);
    }
  }
}

@media (max-width: 575.98px) {
  .dark-theme .header-brand-img {
    &.desktop-logo.theme-logo {
      display: none !important;
    }

    &.icon-logo {
      display: none !important;

      &.theme-logo {
        display: block !important;
      }
    }
  }
}
.dark-theme .main-sidebar-body .nav-link:hover{
        color: #33689C;
}
.dark-theme .sub-slide-item{
        color: #bfbfe0;
}
.dark-theme .sub-slide-menu .sub-slide-item2{
        color: #bfbfe0;
}
.dark-theme .second-sidemenu .sub-slide .is-expanded .angle1{
        color: #bfbfe0;
}
.dark-theme .second-sidemenu .sub-slide-menu .is-expanded .angle2{
        color: #bfbfe0;
}
.dark-theme .second-sidemenu .sub-slide .angle1{
        color: #bfbfe0;
}
.dark-theme .second-sidemenu .sub-slide-menu .angle2{
        color: #bfbfe0;
}
.dark-theme .side-menu .nav-sub-link.with-sub1 i.angle1{
        color: #bfbfe0;
}
.dark-theme .side-menu .nav-sub1 .nav-sub-link.with-sub2 i.angle2{
        color: #bfbfe0;
}
.dark-theme .side-menu .nav-sub-item.show .nav-sub-link.with-sub1 i.angle1{
        color: #bfbfe0;
}
.dark-theme .side-menu .nav-sub1 .nav-sub-item.show .nav-sub-link.with-sub2 i.angle2{
        color: #bfbfe0;
}
.dark-theme .tree li.branch {
    background: #101328;
}
.dark-theme .tree li{
    border: 1px solid rgba(255, 255, 255, 0.05);
    color: #ffffff;
}
.dark-theme .tree li a{
    color: #ffffff;
}
.dark-theme .tree li.branch li {
    background: #101328;
}
.dark-theme .tree ul:before {
    border-left: 2px solid #1c1c38;
	
}	
.dark-theme .tree ul li:before {
    border-top: 2px solid #1c1c38;
}
	