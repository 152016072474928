$background: #eff1f9;
$color:#334151;

/*Color variables*/
$primary:#33689C; 
$secondary:#eb6f33;
$success:#03c895;
$warning:#ffc107;
$danger:#ff473d;
$info:#01b8ff;
$pink:#f10075;
$teal:#00cccc;
$purple:#6f42c1;
$orange:#fd7e14;
$dark:#4d6a98;
$indigo:#4b0082;
$white:#ffffff;
$black:#000000;
$muted:#99a6b7;

/*shadow variables*/
$shadow: 0 8px 16px 0 rgba(162, 169, 204,.24);

/*border variables*/
$border: #e1e6f1;


/* dark theme */
$dark-theme:#101329;

	

